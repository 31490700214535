<div class="modal-header">
    <h4 class="modal-title">{{label}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <textarea style="width: 100%; height: 150px;" [(ngModel)]="value"></textarea>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">{{'Close'|translate}}</button>
    <button type="button" class="btn btn-primary btn-outline-dark" (click)="ok()">{{'Ok'|translate}}</button>
</div>

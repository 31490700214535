import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  page = {
    size: 10,
    pageNumber: 0,
    totalElements: 0
  };

  sorts = [
    {
      prop: 'id',
      dir: 'desc'
    }
  ];

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
  }

  setSort(sortInfo) {
    this.sorts = sortInfo.sorts;
  }

}

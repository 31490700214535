<div class="breadcrumb">
    <ng-template ngFor let-item let-i="index" [ngForOf]="items">
        <i *ngIf="i > 0" class="fa fa-chevron-right ml-2 mr-2" aria-hidden="true"></i>

        <a *ngIf="item.route" [routerLink]="item.route">
            <i *ngIf="item.icon" [ngClass]="item.icon"></i> {{item.title | async}}
        </a>

        <a *ngIf="item.url" [attr.href]="item.url">
            <i *ngIf="item.icon" [ngClass]="item.icon"></i> {{item.title | async}}
        </a>

        <span *ngIf="!item.route && !item.url"><i *ngIf="item.icon" [ngClass]="item.icon"></i> {{item.title | async}}</span>
    </ng-template>
</div>
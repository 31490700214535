import {
    ApplicationRef,
    Component, ElementRef, forwardRef, Host, Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit, Optional,
    ViewChild,
    ViewEncapsulation,
    Directive
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import {
    DataType,
    Entity,
    EntityAspect,
    EntityManager,
    EntityQuery,
    EntityType,
    ValidationError,
    NavigationProperty

} from '@cime/breeze-client';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ViewMode } from '@common/models/view-mode';
import { ControlSize } from '@common/classes/control-size';
import { FileRestrictions, FileInfo, SelectEvent, ClearEvent } from '@progress/kendo-angular-upload';
import { TranslateService } from '@ngx-translate/core';
import { AppFormComponent } from '@common/components/app-form/app-form.component';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import { FloatingLabelModule } from '@progress/kendo-angular-label';

export enum AppControlType {
    String = 'string',
    TextArea = 'textarea',
    Boolean = 'boolean',
    DateTime = 'datetime',
    Number = 'number',
    Array = 'array',
    Password = 'password',
    CodeList = 'codelist',
    File = 'file',
    Static = 'static'
}

const defaultSelectLabel = (item) => `${item.customText || ((item.code || item.id) + ((item.name) ? (' - ' + item.name) : ''))}`;

// TODO:  - split codelist component
//        - remote filtering
@Component({
    selector: 'app-control',
    templateUrl: 'app-control.component.html',
    styleUrls: [
        'app-control.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppControlComponent,
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class AppControlComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor  {
    AppControlType: typeof AppControlType = AppControlType;
    errors: ValidationError[] = [];

    private _date: Date;
    private collectionRowEntityType;

    filteredOptions: any[] = [];
    file; // used for single selection
    fileRestrictions: FileRestrictions = {
        maxFileSize: environment.settings.appControl.fileMaxSize,
        allowedExtensions: environment.settings.appControl.fileAllowedExtensions
    };
    showTooltip = environment.settings.validation.errors.showTooltip;
    showFeedback = environment.settings.validation.errors.showFeedback;

    isBusy = false;
    private _multiselectValue;

    private val;
    private _subscription;
    // private _syntheticProperty;
    private childEntityName: string;
    private fkName: string;
    codeListValue: string;
    fetchCodeListDebounced = _.debounce(this.fetchCodeList, 200);
    applyFilterDebounced = _.debounce(this.applyFilter, 200);
    private dataType;
    isFocus;
    isMouseenter;

    @Input() options: any[] = [];
    @Input() type: AppControlType;
    @Input() label: string;
    @Input() ngModel: NgModel;
    @Input() modelPath?: string;
    @Input() path?: string;
    @Input() entity?: Entity;
    @Input() property?: string;
    @Input() codelist?: string;
    @Input() codelistTake: number = environment.settings.appControl.codelist.take;
    @Input() selectLabel = defaultSelectLabel;
    @Input() codelistFilterCondition: (x) => boolean;
    @Input() fetch: (search, selectedValue) => Promise<any[]>;
    @Input() size: ControlSize = environment.settings.appControl.size;
    @Input() multi = environment.settings.appControl.multi;
    @Input() time = environment.settings.appControl.time;
    @Input() format;
    @Input() min;
    @Input() max;
    @Input() static = false;
    @Input() isDisabled;
    @Input() blur = _.noop;
    @Input() click;
    @Input() popupSettings: PopupSettings;
    @Input() floatingLabelModule: FloatingLabelModule;
    @Input() fetchOnOpen: boolean;
    @Input() filter: (item, search) => boolean;
    @Input() textField = 'label';
    @Input() valueField = 'value';
    @Input() nullable = null;
    @Input() isImportant = false;

    @ViewChild('tooltip', { static: false }) tooltip;

    get isEntity() {
        return this.entity && this.entity.entityAspect instanceof EntityAspect;
    }

    get navigationProperty() {
        if (!this.isEntity) {
            return this.property;
        }
        if (!this.property || !this.entity) {
            return this.property;
        }
        if (this.entity.entityType.getProperty(this.property + 'Id')) {
            return this.property + 'Id';
        }

        return this.property;
    }

    get breezePropertyToAppControlType() {
        if (this.isEntity) {
            const property = this.entity.entityType.getProperty(this.navigationProperty) as any;

            if (!property) {
                throw new Error(`[Property = '${this.property}'] does not exists on [modelType = '${this.entity.entityType.shortName}']`);
            }

            if (this.isCodeList) {
                return AppControlType.CodeList;
            }

            this.dataType = property.dataType;
            switch (property.dataType) {
                case DataType.Boolean:
                    return AppControlType.Boolean;
                case DataType.DateTime:
                    return AppControlType.DateTime;
                case DataType.Int16:
                case DataType.Int32:
                case DataType.Int64:
                case DataType.Decimal:
                case DataType.Single:
                case DataType.Double:
                    return AppControlType.Number;
                case DataType.String:
                    return AppControlType.String;
                case DataType.DateTimeOffset:
                case DataType.Time:
                case DataType.Undefined:
                case DataType.Binary:
                case DataType.Guid:
                case DataType.Byte:
                default:
                    // tslint:disable-next-line:max-line-length
                    throw new Error(`Unsupported [type = '${property.breezeType}'] on [modelPath = '${this.modelPath}'] and [property = '${this.property}']`);
            }
        }
    }

    set value(value) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = value;
        this.onChange(value);
        this.onTouch(value);

        if (this.form) {
            this.form.onChange(this, value);
        }
    }

    get value() {
        return this.val;
    }

    get dateValue() {
        if (_.isDate(this.val)) {
            return this.val;
        }

        if (_.isString(this.val)) {
            const date = new Date(this.val);

            if (!date || !this._date || date.getTime() !== this._date.getTime()) {
                this._date = date;
            }

            return this._date;
        }

        return this.val;
    }

    set dateValue(value) {
        this._date = value;

        if (_.isDate(value)) {
            this.value = value.toISOString();
        } else {
            this.value = value;
        }
    }

    get multiselectValue() {
        if (this.childEntityName && this.value) {
            const codes = this.value.map(x => x[this.fkName]);
            if (_.xor(codes, this._multiselectValue).length) {
                this._multiselectValue = codes;
            }
            return this._multiselectValue;
        } else {
            return this.value;
        }
    }

    set multiselectValue(value) {
        if (this.childEntityName) {
            this._multiselectValue = value;
            _.each(value, id => {
                if (!id) { return; }
                const elementPresent = _.find(this.entity[this.property], x => {
                    return x[this.fkName] === id;
                });
                if (!elementPresent) {
                    const newEntity = this.entity.entityAspect.entityManager.createEntity(this.childEntityName);
                    newEntity[this.fkName] = id;
                    this.entity[this.property].push(newEntity);
                }
            });
            _.each(_.clone(this.entity[this.property]), x => {
                const elementPresent = _.find(value, id => {
                    return x[this.fkName] === id;
                });
                if (!elementPresent) {
                    x.entityAspect.setDeleted();
                }
            });
            this.onChange(this.value);
            this.onTouch(this.value);
            this.form?.onChange(this, this.value);
        } else {
            this.value = value;
        }
    }

    onChange: any = _.noop;
    onTouch: any = _.noop;

    constructor(
        private entityManager: EntityManager,
        private applicationRef: ApplicationRef,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        public elementRef: ElementRef,
        @Optional() @Host() @Inject(forwardRef(() => AppFormComponent)) private form?: AppFormComponent
    ) {
        // entityManager is a global instance
        // if app-component logic is changed so that navigation properties are set instead of synthetic
        // then an empty copy should be created (or the one from entity.entityAspect.entityManager should be used
    }

    setNumericFormat() {
        if (_.isString(this.format)) {
            return;
        }
        switch (this.dataType) {
            case DataType.Int16:
            case DataType.Int32:
            case DataType.Int64:
                this.format = '#';
                break;
            case DataType.Decimal:
                this.format = 'n3';
                break;
            default:
                this.format = '#';
        }
    }


    ngOnInit() {
        this.setNumericFormat();

        if (!this.type && this.isEntity) {
            this.type = this.breezePropertyToAppControlType;
            this.setNumericFormat();
        }

        if (!this.type) {
            this.type = AppControlType.String;

        }

        // this._syntheticProperty = this.navigationProperty;

        if ((this.isDisabled === undefined || this.isDisabled === false) && this.activatedRoute.snapshot.data &&
            this.activatedRoute.snapshot.data.mode === ViewMode.view) {
            this.isDisabled = true;

        }

        if (this.form) {
            this.form.registerAppControl(this);
        }

    }

    ngOnDestroy() {
        if (this._subscription) {
            this.entity.entityAspect.validationErrorsChanged.unsubscribe(this._subscription);
        }

        if (this.form) {
            this.form.unregisterAppControl(this);
        }
    }

    ngOnChanges(changes) {
        if (changes && changes.ngModel && changes.ngModel.firstChange) {
            this.writeValue(changes.ngModel.currentValue);
        }

        if (_.isArray(this.value) && this.isEntity && (this.value as any).navigationProperty && this.codelist) {
            this.childEntityName = (this.value as any).navigationProperty.entityType.shortName;
            const idProperty = _.find((this.value as any).navigationProperty.entityType.navigationProperties, x => x.entityType.shortName === this.codelist);
            this.fkName = idProperty.name + 'Id';
        }

        if (changes && changes.entity && this.isEntity) {
            if (this._subscription) {
                this.entity.entityAspect.validationErrorsChanged.unsubscribe(this._subscription);
            }

            this._subscription = this.entity.entityAspect.validationErrorsChanged.subscribe(() => {
                const validationErrors = _.filter(this.entity.entityAspect.getValidationErrors(), (validatorError: ValidationError) => {
                        return validatorError.propertyName === this.navigationProperty || validatorError.propertyName === this.property;
                    }
                );

                this.errors = validationErrors;

                if (this.errors.length > 0) {
                    this.tooltip.ngbTooltip = _.map(this.errors, x => x.errorMessage).join('\n');
                    this.tooltip.open();
                } else {
                    this.tooltip.ngbTooltip = null;
                    this.tooltip.close();
                }
            });
        }

        if (this.isEntity && !this.type) {
            this.type = this.breezePropertyToAppControlType;
        }

        if (changes && (changes.entity || changes.codelist || changes.property)) {
            this.fetchCodeList();
        }

        if (changes && changes.entity && this.type === AppControlType.File) {
            this.fetchFiles();
        }

        if (changes && changes.options && this.type === AppControlType.CodeList) {
            this.filteredOptions = this.options;
        }

        const currentValue = changes.ngModel && changes.ngModel.currentValue;
        if (currentValue && this.type === AppControlType.CodeList && this.static) {
            // TODO: handle multiselect
            this.fetchCodeListItem(currentValue)
                .then(x => this.codeListValue = x);
        }
    }

    fetchFiles() {
        const navigationProperty = this.getNavigationProperty();
        if (navigationProperty) {
            if (!navigationProperty.isScalar) {
                this.multi = true;
                this.collectionRowEntityType = navigationProperty.entityType;
            } else {
                this.multi = false;
            }
        }

        // Retrieve attachments
        const ids = [];
        if (this.multi) {
            _.each(this.value, (entity) => {
                if (!entity.attachment) {
                    ids.push(entity.attachmentId);
                }
            });
        } else if (navigationProperty && this.entity[navigationProperty.name]) {
            const id = this.entity[this.navigationProperty];
            if (id) {
                ids.push(id);
            }
        }

        if (this.multi || ids.length) {
            this.options.push({ name: '' }); // Hack in order to have a li tag generated
        }

        if (!ids.length) {
            return;
        }

        const query = new EntityQuery('Attachments')
            .withParameters({
                $method: 'POST',
                $data: {
                    ids: ids
                }
            });

        return this.entity.entityAspect.entityManager.executeQuery(query).then((data) => {
            if (!this.multi) {
                this.file = data.results[0];
            }

            this.applicationRef.tick();
        });
    }

    private getNavigationProperty() {
        if (!this.isEntity) {
            return null;
        }

        let navigationProperty: NavigationProperty;
        const property = this.entity.entityType.getProperty(this.property) as any;
        if (property instanceof NavigationProperty) {
            navigationProperty = property;
        } else {
            navigationProperty = _.find(this.entity.entityType.foreignKeyProperties,
                fk => fk.name === this.property).relatedNavigationProperty;
        }

        return navigationProperty;
    }

    fetchCodeList(search = null) {
        if (!this.isCodeList) {
            return;
        }
        if (this.fetch) {
            this.fetch(search, this.value).then((data) => {
                this.filteredOptions = this.options = this.filteredOptions = data;
            });
            return;
        }
        if (!this.codelist) {
            const property = this.entity.entityType.getProperty(this.property) as any;
            if (property.entityType instanceof EntityType) {
                this.codelist = (property.entityType as EntityType).shortName;
            } else {
                this.codelist = _.find(this.entity.entityType.foreignKeyProperties,
                    fk => fk.name === this.property).relatedNavigationProperty.entityType.shortName;
            }
        }
        let selectedIds = _.isArray(this.value) ? this.value : (this.value ? [this.value] : null);
        if (selectedIds && this.childEntityName) {
            selectedIds = selectedIds.map(x => x[this.fkName]);
        }
        const query = new EntityQuery('CodeList')
            .withParameters({
                $method: 'POST',
                $data: {
                    name: this.codelist,
                    selectedIds,
                    filter: search,
                    take: this.codelistTake
                }
            });

        this.isBusy = true;

        return this.entityManager.executeQuery(query).then((data) => {
            if (_.isFunction(this.codelistFilterCondition)) {
                data.results = data.results.filter((x: any) => this.codelistFilterCondition(x));
            }
            this.options = data.results.map((x: any) => ({
                label: this.selectLabel(x),
                value: x.id
            }));
            this.filteredOptions = this.options.filter((x, i) => x.value === this.value || i < 100);

            this.isBusy = false;

            this.applicationRef.tick();
        }).catch((error) => {
            this.isBusy = false;
        });
    }

    fetchCodeListItem(value) {
        const selectedIds = _.isArray(value) ? value : (value ? [value] : null);
        const query = new EntityQuery('CodeList')
            .withParameters({
                $method: 'POST',
                $data: {
                    name: this.codelist,
                    selectedIds: selectedIds,
                    filter: null
                }
            })
            .take(1);

        return this.entityManager.executeQuery(query)
            .then(response => {
                const item = _.find(response.results, x => x.id === value || x.code === value);

                if (!item) {
                    console.error(`${this.value} not found in codelist ${this.codelist}`);
                    return '-';
                }

                return this.selectLabel(item);
            });
    }

    writeValue(obj: any): void {
        this.val = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // this.isDisabled = isDisabled;
    }

    get isCodeList() {
        if (this.type === AppControlType.File) {
            return false;
        }

        if (this.codelist || this.navigationProperty !== this.property) {
            return true;
        }

        if (this.type === AppControlType.CodeList && this.fetch) {
            return true;
        }

        if ((!this.type || this.type === AppControlType.CodeList) && // to avoid type="file"
            this.isEntity &&
            _.some(this.entity.entityType.foreignKeyProperties, fk => fk.name === this.property)) {
            return true;
        }

        return false;
    }

    public isItemSelected(val) {
        if (this.childEntityName) {
            const selectedValues = _.map(this.entity[this.property], x => x[this.fkName]);
            return selectedValues.some(x => x === val);
        } else {
            return this.value && this.value.some(x => x === val);
        }
    }

    onCodelistOpen() {
        if (this.fetch && (!this.options || this.options.length <= 1)) {
            this.fetchCodeList();

            return;
        }

        this.filteredOptions = this.options.filter((x, i) => x.value === this.value || i < 100);
    }

    getTooltip(value) {
        if (!value) {
            return undefined;
        }

        if (!_.isArray(value)) {
            value = [value];
        }

        const labels = _.map(_.filter(this.options, (x: any) => value.includes(x.value)), (x: any) => x.label);

        return labels && labels.join('\n');
    }

    addFiles(event: SelectEvent) {
        if (this.tooltip.ngbTooltip) {
            this.tooltip.ngbTooltip = null;
            this.tooltip.close();
        }

        if (_.some(event.files, file => file.validationErrors && file.validationErrors.length > 0)) {
            const errors = _.chain(event.files)
                .filter(file => file.validationErrors && file.validationErrors.length > 0)
                .map(file => file.validationErrors)
                .flatten()
                .uniq()
                .map(msg => this.translateService.instant(msg))
                .value();
            this.tooltip.ngbTooltip = errors.join('\n');
            this.tooltip.open();
            event.preventDefault();
            return;
        }

        if (this.multi) {
            event.preventDefault(); // Avoid adding another li tag
            if (!_.isArray(this.value)) {
                this.value = [];
            }

            _.each(event.files, (file, i) => {
                this.loadFile(file, content => {
                    (<any>file).content = content;
                    if (this.isEntity) {
                        const entityManager = this.entity.entityAspect.entityManager;
                        const row: any = entityManager.createEntity(this.collectionRowEntityType);
                        row.attachment = entityManager.createEntity('Attachment', { content: content, name: file.name });
                        this.val.push(row);
                    } else {
                        this.value = this.value.slice(); // Copy array in order to update the grid
                        this.value.push({ name: file.name, content: content });
                    }
                });
            });
        } else {
            const file = event.files[0];
            this.loadFile(file, content => {
                (<any>file).content = content;
                if (this.entity && this.entity[this.navigationProperty]) {
                    event.preventDefault(); // Avoid adding another li tag
                }

                if (this.isEntity) {
                    this.entity[this.getNavigationProperty().name] = this.file =
                        this.entity.entityAspect.entityManager.createEntity('Attachment', { content: content, name: file.name });
                } else {
                    this.value = this.file = { content: content, name: file.name };
                }
            });
        }
    }

    removeFile(attachment) {
        if (this.multi) {
            if (this.isEntity) {
                const parent = _.filter(this.value, (row: any) => row.attachmentId === attachment.id)[0];
                attachment.entityAspect.setDeleted();
                parent.entityAspect.setDeleted();
            } else {
                this.value.splice(this.val.indexOf(attachment), 1);
                this.value = this.value.slice(); // Copy array in order to update the grid
            }
        } else {
            if (this.isEntity) {
                attachment.entityAspect.setDeleted();
            }

            this.value = null;
            this.options = []; // Remove the empty li tag
        }
    }

    clearFiles(event: ClearEvent) {
        // BUG: Bug in FileSelect component which throws for single file
        // TODO: implement when the bug is fixed
    }

    private loadFile(file: FileInfo, onLoaded: (content: string) => void) {
        if (file.validationErrors && file.validationErrors.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = ev => {
            const str = <string>ev.target.result; // data:text/plain;base64,[CONTENT]
            onLoaded(str.substring(str.indexOf(',') + 1));
        };
        reader.readAsDataURL(file.rawFile);
    }

    onKeyUp(event: KeyboardEvent) {
        if (this.form) {
            this.form.onSubmit(this, event);
        }
    }

    onDropdownOpen() {
        if (this.fetchOnOpen || this.fetch && (!this.options || this.options.length <= 1)) {
            this.applyFilter();

            return;
        }

        this.filteredOptions = this.options.filter((x, i) => x.value === this.value || i < 100);
    }

    async applyFilter(search = null, value = this.value) {
        if (this.fetch) {
            this.fetch(search, value).then((data) => {
                if (_.isFunction(this.filter)) {
                    data = data.filter((x: any) => this.filter(x, search));
                }
                this.filteredOptions = this.options = this.filteredOptions = data;
            });
            return;
        }

        if (!this.isCodeList) {
            this.filteredOptions = this.options?.filter((x, i) =>
                x.value === value ||
                this.filter?.(x, search) === true ||
                (!this.filter && i < this.codelistTake));
            return;
        }

        if (!this.codelist) {
            const property = this.entity.entityType.getProperty(this.property) as any;
            if (property.entityType instanceof EntityType) {
                this.codelist = (property.entityType as EntityType).shortName;
            } else {
                this.codelist = _.find(this.entity.entityType.foreignKeyProperties,
                    fk => fk.name === this.property).relatedNavigationProperty.entityType.shortName;
            }
        }

        let selectedIds = _.isArray(value) ? value : (value ? [value] : null);
        if (selectedIds && this.childEntityName) {
            selectedIds = selectedIds.map(x => x[this.fkName]);
        }
        const query = new EntityQuery('CodeList')
            .withParameters({
                $method: 'POST',
                $data: {
                    name: this.codelist,
                    selectedIds,
                    filter: search,
                    take: this.codelistTake
                }
            });

        this.isBusy = true;

        try {
            const data = await this.entityManager.executeQuery(query);
            if (_.isFunction(this.filter)) {
                data.results = data.results.filter((x: any) => this.filter(x, search));
            }
            this.options = data.results.map((x: any) => ({
                label: this.selectLabel(x),
                value: x.id
            }));
            this.filteredOptions = this.options.filter((x, i) => x.value === value || i < this.codelistTake);

            this.isBusy = false;

            this.applicationRef.tick();
        }
        finally {
            this.isBusy = false;
        }
    }


}

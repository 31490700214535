import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-entity-revisions',
  templateUrl: './entity-revisions.component.html',
  styleUrls: ['./entity-revisions.component.scss']
})
export class EntityRevisionsComponent implements OnInit {

    @Input() revisions;

    constructor() {
    }

    ngOnInit() {
    }

}

// tslint:disable: no-access-missing-member
import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    HostBinding
} from '@angular/core';

import { LocalizationService } from '@progress/kendo-angular-l10n';
import { PagerContextService, PagerElementComponent } from '@progress/kendo-angular-grid';
import { PagerContextChanges } from '@progress/kendo-angular-grid/dist/es2015/pager/pager-context.service';

/**
 * Displays information about the current page and the total number of records ([see example]({% slug paging_grid %}#toc-pager-templates)).
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-pager-info',
    template: `{{currentPageText}} - {{maxItems}}`
})
export class PagerInfoComponent extends PagerElementComponent {

    /**
     * @hidden
     *
     * @memberOf PagerInfoComponent
     */
    public get maxItems(): number {
        return Math.min(this.currentPage * this.pageSize, this.total);
    }

    /**
     * @hidden
     *
     * @memberOf PagerInfoComponent
     */
    public get currentPageText(): number {
        return this.total ?
            (this.currentPage - 1) * this.pageSize + 1 :
            0;
    }

    /**
     * @hidden
     *
     * @memberOf PagerInfoComponent
     */
    @HostBinding('class.k-pager-info')
    @HostBinding('class.k-label')
    public get classes(): boolean {
        return true;
    }

    constructor(
        localization: LocalizationService,
        cd: ChangeDetectorRef,
        protected pagerContext: PagerContextService
    ) {
        super(localization, pagerContext, cd);
    }

    protected onChanges({ total, skip, pageSize }: PagerContextChanges): void {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }
}

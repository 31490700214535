import { Routes } from '@angular/router';
import { LoginComponent } from './common/components/login/login.component';
import { LogoutComponent } from './common/components/login/logout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { UnauthorizedComponent } from '@common/components/unauthorized/unauthorized.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UserCreateComponent } from '@common/user-create/user-create.component';


export const ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'createUser', component: UserCreateComponent },
    {
        path: 'public-portal',
        loadChildren: './public-portal/public-portal.module#PublicPortalModule',
        data: { title: marker('Public Portal') }
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            { path: '401', component: UnauthorizedComponent },
            { path: 'logout', component: LogoutComponent },
            {
                path: 'administration',
                loadChildren: './administration/administration.module#AdministrationModule',
                data: { title: marker('Administration') }
            },
            {
                path: 'vessel-announcement',
                loadChildren: './vessel-announcement/vessel-announcement.module#VesselAnnouncementModule',
                data: { title: marker('Vessel annoucement') }
            },
            {
                path: 'service-request',
                loadChildren: './service-request/service-request.module#ServiceRequestModule',
                data: { title: marker('Service request') }
            },
            {
                path: 'warehouse-document',
                loadChildren: './warehouse-document/warehouse-document.module#WarehouseDocumentModule',
                data: { title: marker('Warehouse document') }
            },
            {
                path: 'stock-record',
                loadChildren: './stock-record/stock-record.module#StockRecordModule',
                data: { title: marker('Stock record') }
            },
            {
                path: 'truck-visit',
                loadChildren: './truck-visit/truck-visit.module#TruckVisitModule',
                data: { title: marker('Truck visit') }
            },
            {
                path: 'vehicle-permit',
                loadChildren: './vehicle-permit/vehicle-permit.module#VehiclePermitModule',
                data: { title: marker('Vehicle permit') }
            },
            {
                path: 'reporting',
                loadChildren: './reporting/reporting.module#ReportingModule',
                data: { title: marker('Reporting') }
            },
            {
                path: 'notification',
                loadChildren: './notification/notification.module#NotificationModule',
                data: { title: marker('Notifications') }
            },
            {
                path: 'home',
                loadChildren: './home/home.module#HomeModule'
            },
            {
                path: '**',
                redirectTo: './home/home.module#HomeModule'
            },
            {
                path: 'codelist/warehouse',
                loadChildren: './administration/codelist/warehouse/warehouse.module#WarehouseModule',
                data: { title: marker('Warehouse') }
            },
            {
                path: 'codelist/cargo-type',
                loadChildren: './administration/codelist/cargo-type/cargo-type.module#CargoTypeModule',
                data: { title: marker('CargoType') }
            }
        ]
    }
];

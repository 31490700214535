import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  sendData$: Observable<any>;
  private sendDataSubject = new Subject<any>();

  constructor() {
    this.sendData$ = this.sendDataSubject.asObservable();
  }

  sendData(data) {
    this.sendDataSubject.next(data);
  }
}

<nav class="bg-white sidebar" [ngStyle]="{ 'width': isCollapsed ? '60px' : '250px' }">
    <ng-template ngFor let-item [ngForOf]="navigation">
        <ul class="nav nav-pills flex-column" *ngIf="item.hasPermission" [ngClass]="{'active': selectedItem === item || isActive(item.route)}">
            <li>
                <a class="nav-link" [ngClass]="item.class" href="javascript:void(0)" style="display: block;" (click)="selectItem(item)">
                    <i *ngIf="item.icon" [ngClass]="item.icon"></i> <span
                        *ngIf="!isCollapsed"><br/>{{item.title | translate}}</span>
                    <span class="bla" [hidden]="isCollapsed"><i class="fa  fa-chevron-left "
                            [ngClass]="{'fa-chevron-left ': item == selectedItem, ' fa-chevron-down': item != selectedItem}"></i></span>
                </a>

                <ul class="nav nav-pills flex-column" [hidden]="isCollapsed || (selectedItem !== item && !isActive(item.route))">
                    <ng-template ngFor let-child [ngForOf]="item.children">
                        <li class="nav-item" *ngIf="child.hasPermission && !child.children" [ngClass]="{'active': isActive(child.route)}">
                            <a class="nav-link" routerLink="{{child.route}}"><i *ngIf="item.icon"
                                    [ngClass]="child.icon"></i> {{child.title | translate}}</a>
                                    
                        </li>

                        <li *ngIf="child.hasPermission && child.children">
                            <a class="nav-link" style="display: block;" (click)="selectItemChild(child)"><i *ngIf="child.icon" href="javascript:void(0)" 
                                [ngClass]="child.icon" style="color: black !important;"></i> <span style="color: black !important;">{{child.title | translate}}</span>

                                <span ><i class="fa  fa-chevron-left"
                                    [ngClass]="{'fa-chevron-left ': !isCollapsedChild, ' fa-chevron-down': isCollapsedChild }"></i></span>
                            </a>


                            <ul class="nav nav-pills flex-column" [hidden]="isCollapsedChild">
                                <ng-template ngFor let-child [ngForOf]="child.children">
                                    <li class="nav-item" *ngIf="child.hasPermission && !child.children" [ngClass]="{'active': isActive(child.route)}">
                                        <a class="nav-link" routerLink="{{child.route}}"><i *ngIf="child.icon"
                                                [ngClass]="child.icon"></i> {{child.title | translate}}</a>
                                                
                                    </li>
                                    
                                </ng-template>
                            </ul>
                        </li>
                    </ng-template>
                </ul>
            </li>
        </ul>
    </ng-template>
</nav>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pad'
})
export class PadPipe implements PipeTransform {
    transform(value: string | number, ...args: any[]): string {
        const maxLength = args[0] || 5;
        const fillString = args[1] || '0';

        return value?.toString().padStart(maxLength, fillString);
    }
}

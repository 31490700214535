import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnInit, Output
} from '@angular/core';
import { AppControlComponent } from '@common/components/app-control/app-control.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-form',
    template: '<ng-content></ng-content>'
})
export class AppFormComponent implements OnInit, AfterViewInit {
    @Output()
    submit = new EventEmitter();
    @Output()
    change = new EventEmitter();

    private appControls: AppControlComponent[] = [];

    constructor(private _el: ElementRef) {

    }

    registerAppControl(appControl: AppControlComponent) {
        this.appControls.push(appControl);
    }

    unregisterAppControl(appControl: AppControlComponent) {
        _.remove(this.appControls, appControl);
    }

    ngOnInit() {

    }

    ngAfterViewInit() {

    }

    onSubmit(appControl: AppControlComponent, event: KeyboardEvent) {
        this.submit.emit({ form: this, appControl: appControl, event: event });
    }

    onChange(appControl: AppControlComponent, value: any) {
        this.change.emit({ form: this, appControl: appControl, value: value });
    }
}

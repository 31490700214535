import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { UserService } from '@common/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class RemoteLogService {

    private logHubConnection: signalR.HubConnection;

    constructor(private userService: UserService) {

    }

    async connect() {
        try {
            this.logHubConnection = new signalR.HubConnectionBuilder()
                .withUrl( environment.serverUrl + '/hubs/log', {
                    accessTokenFactory: () => this.userService.getAccessToken()
                })
                .configureLogging(environment.production ? signalR.LogLevel.Warning : signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();

            this.logHubConnection.on('TotalClients', (totalClients) => {
                if (!environment.production) {
                    console.log('TotalClients', totalClients);
                }
            });

            await this.logHubConnection.start();

            return true;
        } catch (error) {
            return false;
        }
    }

    info(message: string) {
        return this.logHubConnection.invoke('Info', message)
            .catch((error) => console.error(error));
    }

    error(message: string) {
        return this.logHubConnection.invoke('Error', message)
            .catch((error) => console.error(error));
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '@common/services/dialog.service';

import { UserNotificationModalComponent } from '../user-notification-modal/user-notification-modal.component';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    username: string;
    password: string;
    isBusy = false;
    errorMessage: string;
    backgroundImgs = ['bg1.jpg', 'bg2.jpg', 'bg3.jpg', 'bg4.jpg', 'bg5.jpg', 'bg6.jpg', 'bg7.jpg', 'bg8.jpg'];
    chosenImage: string;

    constructor(
        private userService: UserService,
        private router: Router,
        private httpClient: HttpClient,
        private dialogService: DialogService
    ) {
        userService.isAuthenticatedSubject.next(false);
    }
    showChangePassword = false;

    newPassword: string;

    repeatNewPassword: string;

    async signIn() {
        this.isBusy = true;
        this.errorMessage = null;
        const returnUrl = this.router.routerState.snapshot.root.queryParams.returnUrl ?? '/';

        try {
            const value = await this.userService.login(this.username, this.password);
            if (!value) {
                this.isBusy = false;
                this.showChangePassword = true;
            } else {
                const result = <any>await this.httpClient.post('api:///query/HasActiveNotifications', {
                    important: true
                }).toPromise();
                this.isBusy = false;
                this.router.navigate([returnUrl]).then(() => {
                    if (result) {
                        this.dialogService.open(UserNotificationModalComponent, {
                            size: 'xl'
                        });
                    }
                });
            }
        } catch (error) {
            this.isBusy = false;
            this.errorMessage = error;
        }
    }

    changePassword() {
        if (this.repeatNewPassword !== this.newPassword) {
            this.errorMessage = 'The repeated password is not the same';
            return;
        }

        const returnUrl = this.router.routerState.snapshot.root.queryParams.returnUrl ?? '/';
        this.userService.firstLogin(this.username, this.newPassword).then((value) => {
            this.isBusy = false;
            this.router.navigate([returnUrl]);
        }).catch((errorMessage) => {
            this.isBusy = false;
            this.errorMessage = errorMessage;
        });
    }

    ngOnInit() {
        this.chosenImage = '../../../../assets/img/' + this.backgroundImgs[Math.floor(Math.random() * this.backgroundImgs.length)];
    }

    signUp() {
        return this.router.navigate(['/createUser']);
    }
}

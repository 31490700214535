import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [innerHTML]="message" style="font-size:14px">
        </div>
        <div class="modal-footer">
            <button class="btn btn-xs btn-outline-cancel btn-rounded custom-btn btn-cancel" (click)="activeModal.close(false)" translate>Cancel</button>
            <button class="btn btn-xs btn-outline-success btn-rounded custom-btn btn-search" (click)="activeModal.close(true)" translate>Ok</button>
        </div>
    `,
})
export class ConfirmDialogComponent {
    public title: string;
    public message: string;

    constructor(public activeModal: NgbActiveModal) {

    }
}

<div class="">
<div class="modal-header">

    <h5 class="modal-title"><i class="fa fa-tasks" aria-hidden="true">&nbsp;</i>{{ options.title }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancelAction()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-container *ngIf="options.properties?.length > 0">
        <div class="row" *ngFor="let property of options.properties; let i = index" [ngClass]="{ 'mt-2': i > 0 }">
            <div class="col">
                <app-control
                    [type]="property.type"
                    [(ngModel)]="model[getName(property)]" [entity]="" property="model[getName(property)]"
                    [property]="getName(property)"
                    [label]="property.label"
                    [codelist]="property.codelist"
                    [time]="property.time"
                    [selectLabel]="property.selectLabel"
                    [isDisabled]="property.isDisabled">
                </app-control>
            </div>
        </div>
    </ng-container>
    <ng-template #template></ng-template>
</div>
<div class="modal-footer">
    <button class="btn btn-xs btn-outline-cancel btn-rounded custom-btn btn-cancel" (click)="cancelAction()">{{ options.cancelText || ('Cancel' | translate) }}</button>
    <button class="btn btn-xs btn-outline-success btn-rounded custom-btn btn-search" (click)="confirmAction()">{{ options.confirmText || ('Confirm' | translate) }}</button>
</div>
</div>

<div class="row cont" *ngIf="label">

    <div class="col col-label-height">
        <label class="label-fonts " *ngIf="label" for="">{{label}}</label>
    </div>
    <div class="col-auto" >
        <ng-content></ng-content>
    </div>


</div>


<input *ngIf="!static && type === AppControlType.String" (keyup.enter)="onKeyUp($event)"
    kendoTextBox
    type="text"
    placeholder=" " 
    class="noborder k-textbox form-control form-control-sm "
    [ngClass]="{ 'is-invalid': errors.length > 0, 'border-bottom border-danger' : isImportant }" [disabled]="isDisabled" [(ngModel)]="value" #tooltip="ngbTooltip" 
    placement="top" ngbTooltip="" tooltipClass="error-tooltip" />
    <span class="focus-border"></span>




<textarea *ngIf="!static && type === AppControlType.TextArea" (keyup.enter)="onKeyUp($event)"
    kendoTextArea
    [autoSize]="true"
    class="k-textarea form-control "
    [(ngModel)]="value"
    [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled }" [disabled]="isDisabled" #tooltip="ngbTooltip" placement="top"
    ngbTooltip="" tooltipClass="error-tooltip">
</textarea>


<input *ngIf="!static && type === AppControlType.Password" (keyup.enter)="onKeyUp($event)"
    kendoTextBox
    type="password"
    class="noborder red k-textbox form-control form-control-sm" [ngClass]="{ 'is-invalid': errors.length > 0 }"
    [disabled]="isDisabled" [(ngModel)]="value" #tooltip="ngbTooltip" placement="top" ngbTooltip=""
    tooltipClass="error-tooltip" />


<kendo-numerictextbox *ngIf="!static && type === AppControlType.Number" (keyup.enter)="onKeyUp($event)"
    class="form-control form-control-sm "
    [(ngModel)]="value"
    (blur)="blur()"
    [format]="format"
    [min]="min"
    [max]="max"
    [decimals]="2"
    [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled }" [disabled]="isDisabled" #tooltip="ngbTooltip" placement="top"
    ngbTooltip="" tooltipClass="error-tooltip" >
</kendo-numerictextbox>
<span class="focus-border"></span>


<kendo-fileselect *ngIf="!static && type === AppControlType.File && !isDisabled"
    #tooltip="ngbTooltip" placement="top" ngbTooltip="" tooltipClass="error-tooltip"
    [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled }"
    [multiple]="multi"
    [restrictions]="fileRestrictions"
    [(ngModel)]="options"
    (clear)="clearFiles($event)"
    (select)="addFiles($event)"
    (remove)="removeFiles($event)">
    <kendo-upload-messages
        [select]="multi ? ('Select files...' | translate) : ('Select file...' | translate)">
    </kendo-upload-messages>
    <ng-template kendoUploadFileTemplate let-state="state">
        <app-upload-single-file
            *ngIf="!multi"
            (removeFile)="removeFile($event)"
            [file]="file">
        </app-upload-single-file>
        <app-upload-multiple-files
            class="k-file-multiple"
            *ngIf="multi"
            (removeFile)="removeFile($event)"
            [files]="value">
        </app-upload-multiple-files>
    </ng-template>
</kendo-fileselect>
<div *ngIf="type === AppControlType.File && (isDisabled || static)">
    <app-upload-single-file
        *ngIf="!multi"
        [disabled]="true"
        [file]="file">
    </app-upload-single-file>
    <app-upload-multiple-files
        class="k-file-multiple"
        *ngIf="multi"
        [disabled]="true"
        [files]="value">
    </app-upload-multiple-files>
</div>


<kendo-combobox *ngIf="!static && type === AppControlType.CodeList && !multi" (keyup.enter)="onKeyUp($event)"
    class="form-control  form-control-sm"
    (open)="onCodelistOpen()"
    [textField]="'label'" [valueField]="'value'" [valuePrimitive]="true" [data]="filteredOptions" [(ngModel)]="value"
    [filterable]="true" (filterChange)="fetchCodeListDebounced($event)"
    [disabled]="isDisabled"
    [popupSettings]="{width:'auto',height:'auto'}"
    [loading]="isBusy"
    title="{{getTooltip(value)}}"
    #tooltip="ngbTooltip" placement="top" ngbTooltip=""
    tooltipClass="error-tooltip" [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled }">
</kendo-combobox>


<div *ngIf="!static && type === AppControlType.CodeList && multi" style="position: relative;">
    <kendo-multiselect (keyup.enter)="onKeyUp($event)"
                       (mouseenter)="isMouseenter=true"
                       (mouseleave)="isMouseenter=false"
                       (blur)="isFocus=false"
                       (focus)="isFocus=true"
                       class="form-control form-control-sm"
                       (open)="onDropdownOpen()"
                       [textField]="textField" [valueField]="valueField" [valuePrimitive]="true" [data]="filteredOptions" [(ngModel)]="multiselectValue"
                       [filterable]="true" (filterChange)="applyFilterDebounced($event)"
                       [loading]="isBusy"
                       [clearButton]="nullable !== false"
                       [readonly]="isDisabled"
                       [popupSettings]="popupSettings"
                       #tooltip="ngbTooltip" placement="top"
                       title="{{getTooltip(value)}}"
                       ngbTooltip=""
                       tooltipClass="error-tooltip" [ngClass]="{ 'is-invalid': errors.length > 0, 'is-read-only': isDisabled }"
                       style="height:100% !important;">
        <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <div style="padding: 4px;">
                <input type="checkbox" class="k-checkbox" [checked]="isItemSelected(dataItem.value)">
                <label class="k-checkbox-label">{{ dataItem.label }}</label>
            </div>
        </ng-template>
    </kendo-multiselect>
    <span class="multiselect-count" *ngIf="!(isBusy || isMouseenter || isFocus) && multiselectValue?.length > 0">({{multiselectValue.length}})</span>
</div>


<div *ngIf="!static && type === AppControlType.DateTime && !time">
    <i ngbTooltip="{{'Clear'}}" placement="bottom" *ngIf="dateValue && !datePicker.disabled" (click)="dateValue=null" class="k-icon k-clear-value k-i-close date-time-close"></i>
    <kendo-datepicker (keyup.enter)="onKeyUp($event)"
                      class="form-control"
                      #datePicker
                      [navigation]="false"
                      [readonly]="isDisabled"
                      [ngClass]="{ 'is-invalid': errors.length > 0 }" [disabled]="isDisabled" [(ngModel)]="dateValue"
                      #tooltip="ngbTooltip"
                      placement="top" ngbTooltip="" tooltipClass="error-tooltip">
                      
    </kendo-datepicker>
    
</div>

<div *ngIf="!static && type === AppControlType.DateTime && time" >
    <i ngbTooltip="{{'Clear'}}" placement="bottom" *ngIf="nullable !== false && dateValue && !isDisabled" (click)="dateValue=null" class="k-icon k-clear-value k-i-close date-time-close"></i>
    <kendo-datetimepicker (keyup.enter)="onKeyUp($event)"
        #dateTimePicker
        class="form-control"
          [readonly]="isDisabled"
        [ngClass]="{ 'is-invalid': errors.length > 0, 'is-read-only': isDisabled }" [(ngModel)]="dateValue" [disabled]="isDisabled" 
        #tooltip="ngbTooltip"
        placement="top" ngbTooltip="" tooltipClass="error-tooltip">
        <kendo-datetimepicker-messages
                    today="{{'Today' | translate}}" 
                    toggle="Cambiar calendario"
                    dateTab="{{'Date' | translate}}" 
                    dateTabLabel="{{'Date' | translate}}" 
                    timeTab="{{'Time' | translate}}" 
                    timeTabLabel="{{'Time' | translate}}" 
                    now="{{'Now' | translate}}" 
                    nowLabel="{{'Now' | translate}}" 
                    accept="{{'Accept' | translate}}" 
                    acceptLabel="{{'Accept' | translate}}" 
                    cancel="{{'Cancel' | translate}}"               
                    cancelLabel="{{'Cancel' | translate}}" 
                >
                </kendo-datetimepicker-messages>
    </kendo-datetimepicker>
</div>
<app-checkbox (keyup.enter)="onKeyUp($event)" *ngIf="!static && type === AppControlType.Boolean" [size]="size" [(ngModel)]="value" [disabled]="isDisabled" [click]="click"></app-checkbox>


<div *ngIf="type === AppControlType.Static" class="form-control-plaintext form-control-sm">{{value}}</div>

<div *ngIf="static" [ngSwitch]="type" class="form-control-plaintext form-control-sm">
    <div *ngSwitchCase="AppControlType.CodeList">{{codeListValue}}</div>
    <div *ngSwitchCase="AppControlType.DateTime">{{value | date: 'short'}}</div>
    <div *ngSwitchCase="AppControlType.Number">{{value | kendoNumber:format }}</div>
    <div *ngSwitchDefault>{{value}}</div>
</div>
<div class="invalid-feedback" *ngIf="showFeedback && errors.length > 0">
    {{errors[0].errorMessage}}
</div>

import { Component, Input, Output } from '@angular/core';
import { NgbPanel, NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from 'events';



@Component({
    selector: 'app-accordion-header',
    template: `


    <div class="row" >
    <div class="pointer" (click)="toggle()"  style="margin-top:5px; color:#1D5E96;">
    <fa-icon class="h3 float-left" style="padding-left:25px" [icon]="panel.isOpen ? 'chevron-circle-up' : 'chevron-circle-down'"></fa-icon>
</div>
    <div class="" [ngClass]="{ 'pointer': !onlyClose }" (click)="onlyClose ? close() : toggle()" >
        <ng-content></ng-content>
    </div>
    </div>
    `,
    styles: [
        `.pointer {
            cursor: pointer;
        `
    ]

})
export class AccordionHeaderComponent {
    constructor(public accordion: NgbAccordion) {
        this.panelChange = this.accordion.panelChange;
    }

    @Input() panel: NgbPanel;

    @Input() onlyClose: boolean;

    @Output() panelChange;

    toggle() {
        this.accordion.toggle(this.panel.id);
    }

    close() {
        if (this.panel.isOpen) {
            this.accordion.toggle(this.panel.id);
        }
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-multiple-insert-modal',
    templateUrl: './multiple-insert-modal.component.html',
    styleUrls: ['./multiple-insert-modal.component.scss']
})
export class MultipleInsertModalComponent implements OnInit {

    @Input()
    value;
    @Input()
    label;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        if (this.value) {
            this.value = this.value.split(',').map(x => x.trim()).join('\n');
        }
    }

    ok() {
        this.activeModal.close(this.value
            ? this.value.split('\n').map(x => x.trim()).filter(x => x.length).join(',')
            : this.value);
    }

}


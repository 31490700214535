import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-upload-action-buttons',
    template: `
        <strong *ngIf="!multiple && !disabled" class="k-upload-status">
            <button type="button"
                    class="k-button k-upload-action"
                    style="pointer-events: all;"
                    *ngIf="canDownload(file)"
                    [attr.tabIndex]="-1"
                    (click)="download(file)">
          <span class="k-icon k-i-eye"
                [attr.aria-label]="'Download' | translate"
                [attr.title]="'Download' | translate">
          </span>
            </button>
            <button type="button"
                    *ngIf="canRemove()"
                    class="k-button k-upload-action"
                    [attr.tabIndex]="-1"
                    (click)="remove()">
          <span class="k-icon k-delete k-i-x"
                [attr.aria-label]="'Remove' | translate"
                [attr.title]="'Remove' | translate">
          </span>
            </button>
        </strong>

        <button *ngIf="!multiple && disabled && canDownload(file)" class="btn btn-outline-secondary" type="button" (click)="download(file)">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
        </button>

        <span *ngIf="multiple">
        <button class="btn btn-default btn-xs"
                [attr.aria-label]="'Download' | translate"
                [attr.title]="'Download' | translate"
                *ngIf="canDownload(file)"
                (click)="download(file)">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
        </button>
        <button class="btn btn-danger btn-xs"
                [attr.aria-label]="'Remove' | translate"
                [attr.title]="'Remove' | translate"
                *ngIf="canRemove()"
                (click)="remove()">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
        </button>
    </span>
    `
})
export class UploadActionButtonsComponent {

    @Input() file: any;
    @Input() disabled: boolean;
    @Input() multiple: boolean;

    @Output() public removeFile: EventEmitter<any> = new EventEmitter<any>();

    public static openInNewWindow(name: string, content: string) {
        const binaryString = window.atob(content);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // add other mime types that can be opened in new tab if needed
        const mimeType = name.split('.').pop() === 'pdf'
            ? 'application/pdf'
            : 'application/octet-stream';

        const blob = new Blob([bytes], { type: mimeType });
        const link = window.URL.createObjectURL(blob);
        window.open(link);
    }
    public static downloadFile(name: string, content: string) {
        const binaryString = window.atob(content);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        (link as any).download = name;
        link.click();
    }

    constructor(private http: HttpClient) {
    }

    canRemove(): boolean {
        return !this.disabled;
    }

    remove(): void {
        this.removeFile.emit(this.file);
    }

    canDownload(file) {
        return file && (file.id > 0 || !!file.content);
    }

    async download(file) {
        if (file.id > 0) {
            const attachment: any = await this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`).toPromise();
            UploadActionButtonsComponent.downloadFile(attachment.name, attachment.content);
        } else {
            UploadActionButtonsComponent.downloadFile(file.name, file.content);
        }
    }
}

import { Injectable, ComponentFactoryResolver, StaticProvider } from '@angular/core';
import { SplitterComponent } from '@progress/kendo-angular-layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConsoleWindowService {

    private consoleSplitter: SplitterComponent;

    public opened = false;
    public componentSubject = new BehaviorSubject<any>(null);

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    }

    initialize(consoleSplitter: SplitterComponent) {
        this.consoleSplitter = consoleSplitter;
    }

    public open(component, dataProviders: StaticProvider[]) {
        this.opened = true;
        // set the component and data providers that will be used by the console-content directive
        this.componentSubject.next({ component: component, dataProviders: dataProviders });
    }

    public close() {
        this.opened = false;
        this.componentSubject.next(null);
    }
}

import { MenuItem } from '@common/services/navigation.service';
import {
    VesselAnnouncementPermissions,
    GeneralCargoServiceRequestPermissions,
    LiquidBulkServiceRequestPermissions,
    WarehouseDocumentPermissions,
    AdministrationPermissions,
    LiquidBulkStockRecordPermissions,
    GeneralCargoStockRecordPermissions,
    VehiclePermitPermissions,
    ReportingPermissions,
    TruckVisitPermissions,
    NotificationPermissions,
    WarehouseDocumentGeneralPermissions
} from '@common/classes/permissions';
import { User } from '@common/models/User';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const NAVIGATION: MenuItem[] = [
    {
        title: marker('Dashboard'),
        icon: 'fa fa-dashboard fa-lg',
        children: [
            {
                title: 'General Overview',
                icon: 'fa fa-pie-chart',
                route: '/home'
            },
            {
                title: 'Statistics',
                icon: 'fa fa-bar-chart',
                route: '/home/statistics'
            }
        ]
    },
    {
        title: marker('Notice board'),
        class: (user: User) => user.hasAnyNotifications ? 'text-danger' : null,
        icon: (user: User) => user.hasAnyNotifications ? 'fa fa-exclamation-circle fa-lg' : 'fa fa-file fa-lg',
        children: [
            {
                title: 'Create',
                icon: 'fa fa-file',
                route: 'notification/create',
                permissions: [
                    NotificationPermissions.Action.create
                ]
            },
            {
                title: 'List',
                icon: 'fa fa-file',
                route: 'notification/list',
                permissions: [
                    NotificationPermissions.View.list
                ]
            },
            {
                title: 'View',
                icon: 'fa fa-file',
                route: 'notification/userlist'
            }
        ]
    },
    {
        title: marker('Vessel announcements'),
        icon: 'fa fa-ship fa-lg',
        permissions: [
            VesselAnnouncementPermissions
        ],
        children: [
            {
                title: marker('Announcements'),
                icon: 'fa fa-ship',
                route: '/vessel-announcement/list',
                permissions: [
                    VesselAnnouncementPermissions.View
                ]
            },
            {
                title: 'Ship list',
                icon: 'fa fa-ship',
                route: '/administration/vessel',
                permissions: [
                    VesselAnnouncementPermissions.View
                ]
            }
        ]
    },
    {
        title: marker('Liquid bulk'),
        icon: 'fa fa-tint fa-lg',
        permissions: [
            LiquidBulkServiceRequestPermissions.View,
            LiquidBulkStockRecordPermissions.View
        ],
        children: [
            {
                title: marker('Orders'),
                icon: 'fa fa-bars',
                route: '/service-request/list/liquid',
                permissions: [
                    LiquidBulkServiceRequestPermissions.View,
                ]
            },
            {
                title: marker('Warehouse Documents'),
                icon: 'fa fa-bars',
                route: '/warehouse-document/list/liquid',
                permissions: [
                    WarehouseDocumentPermissions.View
                ]
            },
            {
                title: marker('Stock records'),
                icon: 'fa fa-bars',
                route: '/stock-record/list/liquid',
                permissions: [
                    LiquidBulkStockRecordPermissions.View
                ]
            }
        ]
    },
    {
        title: marker('General and dry bulk'),
        icon: 'fa fa-inbox fa-lg',
        permissions: [
            GeneralCargoServiceRequestPermissions.View,
            GeneralCargoStockRecordPermissions.View
        ],
        children: [
            {
                title: marker('Orders'),
                icon: 'fa fa-bars',
                route: '/service-request/list/general',
                permissions: [
                    GeneralCargoServiceRequestPermissions.View
                ]
            },
            {
                title: marker('Warehouse Documents'),
                icon: 'fa fa-bars',
                route: '/warehouse-document/list/general',
                permissions: [
                    WarehouseDocumentGeneralPermissions.View
                ]
            },
            {
                title: marker('Stock records'),
                icon: 'fa fa-bars',
                route: '/stock-record/list/general',
                permissions: [
                    GeneralCargoStockRecordPermissions.View
                ]
            }
        ]
    },
    {
        title: marker('Trucks'),
        icon: 'fa fa-truck fa-lg',
        permissions: [
            TruckVisitPermissions.View
        ],
        children: [
            {
                title: marker('Truck Announcements'),
                icon: 'fa fa-bars',
                route: '/truck-visit/list',
                permissions: [
                ]
            },
            {
                title: marker('Announcements Upload'),
                icon: 'fa fa-bars',
                route: '/truck-visit/upload',
                permissions: [
                ]
            }
        ]
    },
    {
        title: marker('Railways'),
        icon: 'fa fa-train fa-lg',
        permissions: [
            AdministrationPermissions
        ],
        children: [
            {
                title: marker('Wagon Announcements'),
                icon: 'fa fa-bars',
                route: '/home',
                permissions: [
                ]
            },
            {
                title: marker('Wagon pre-announcement'),
                icon: 'fa fa-bars',
                route: '/home',
                permissions: [
                ]
            },
            {
                title: marker('Wagon list'),
                icon: 'fa fa-bars',
                route: '/home',
                permissions: [
                ]
            },
            {
                title: marker('Railway Orders'),
                icon: 'fa fa-bars',
                route: '/home',
                permissions: [
                ]
            }
        ]
    },
    {
        title: marker('Permits'),
        icon: 'fa fa-address-card-o fa-lg',
        permissions: [
            VehiclePermitPermissions
        ],
        children: [
            {
                title: marker('Daily Permits'),
                icon: 'fa fa-bars',
                route: '/vehicle-permit/listdaily',
                permissions: [
                    VehiclePermitPermissions.View
                ]
            },
            {
                title: marker('Permanent Permits'),
                icon: 'fa fa-bars',
                route: '/vehicle-permit/listpermanent',
                permissions: [
                    VehiclePermitPermissions.View
                ]
            },
            {
                title: marker('Invoices'),
                icon: 'fa fa-bars',
                route: '/home',
                permissions: [
                ]
            },
            {
                title: marker('Company List'),
                icon: 'fa fa-bars',
                route: '/home',
                permissions: [
                ]
            }
        ]
    },
    {
        title: marker('Reporting'),
        icon: 'fa fa-file',
        permissions: [
            ReportingPermissions
        ],
        children: [
            {
                title: marker('Cargo report'),
                icon: 'fa fa-bars',
                route: '/reporting/cargo',
                permissions: [
                    ReportingPermissions.View.cargoReport
                ]
            },
            {
                title: marker('Cargo manipulations report'),
                icon: 'fa fa-bars',
                route: '/reporting/cargomanipulations',
                permissions: [
                    ReportingPermissions.View.cargoManipulationsReport
                ]
            },
            {
                title: marker('Vessel report'),
                icon: 'fa fa-bars',
                route: '/reporting/vessel',
                permissions: [
                    ReportingPermissions.View.vesselReport
                ]
            },
            {
                title: marker('Parking and permit report'),
                icon: 'fa fa-bars',
                route: '/reporting/parkingandpermitreport',
                permissions: [
                    ReportingPermissions.View.parkingAndPermitReport
                ]
            },
            {
                title: marker('Parking and permit report archive'),
                icon: 'fa fa-bars',
                route: '/reporting/parkingandpermitarchivereport',
                permissions: [
                    ReportingPermissions.View.parkingAndPermitReport
                ]
            },
            {
                title: marker('Dangerous goods report'),
                icon: 'fa fa-bars',
                route: '/reporting/dangerousgoods',
                permissions: [
                    ReportingPermissions.View.dangerousGoodsReport
                ]
            },
            {
                title: marker('Stock report'),
                icon: 'fa fa-bars',
                route: '/reporting/stock',
                permissions: [
                    ReportingPermissions.View.stockReport
                ]
            }
        ]
    },
    {
        title: marker('Archive'),
        icon: 'fa fa-archive fa-lg',
        children: [
            {
                title: marker('Vessel Announcements'),
                icon: 'fa fa-ship',
                route: '/vessel-announcement/list/archive',
                permissions: [
                    VesselAnnouncementPermissions.View
                ]
            },
            {
                title: marker('Orders'),
                icon: 'fa fa-file-text',
                route: '/service-request/list/archive',
                permissions: [
                    LiquidBulkServiceRequestPermissions.View,
                    GeneralCargoServiceRequestPermissions.View
                ]
            },
            {
                title: marker('Warehouse Documents'),
                icon: 'fa fa-file',
                route: '/warehouse-document/list/archive',
                permissions: [
                    WarehouseDocumentGeneralPermissions.View,
                    WarehouseDocumentPermissions.View
                ]
            },
            {
                title: marker('Truck Announcements'),
                icon: 'fa fa-truck',
                route: '/truck-visit/list/archive',
                permissions: [
                ]
            },
        ]
    },
    {
        title: marker('Administration'),
        icon: 'fa fa-cogs fa-lg',
        permissions: [
            AdministrationPermissions
        ],
        children: [
            {
                title: marker('Users'),
                icon: 'fa fa-users',
                route: '/administration/user',
                permissions: [
                    AdministrationPermissions.User.viewAll,
                    AdministrationPermissions.User.viewOwn,
                ]
            },
            {
                title: marker('New Users'),
                icon: 'fa fa-user',
                route: '/administration/user/new',
                permissions: [
                    AdministrationPermissions.User.viewAll,
                    AdministrationPermissions.User.viewOwn,
                ]
            },
            {
                title: marker('Organizations'),
                icon: 'fa fa-building',
                route: '/administration/organization',
                permissions: [
                    AdministrationPermissions.Organization.viewAll,
                    AdministrationPermissions.Organization.viewOwn,
                ]
            },
            {
                title: marker('Roles'),
                icon: 'fa fa-user-plus',
                route: '/administration/role',
                permissions: [
                    AdministrationPermissions.Role.view
                ]
            },
            {
                title: marker('Settings'),
                icon: 'fa fa-cogs',
                route: '/administration/settings',
                permissions: [
                    AdministrationPermissions.Settings.view
                ]
            },
            {
                title: marker('Code lists'),
                icon: 'fa fa-cogs fa-lg',
                permissions: [
                    AdministrationPermissions.Settings.view
                ],
                children: [
                    {
                        title: marker('Warehouses'),
                        icon: 'fa fa-file',
                        route: '/administration/codelist/warehouse',
                        permissions: [
                            AdministrationPermissions.Settings.view
                        ]
                    },
                    {
                        title: marker('CargoTypes'),
                        icon: 'fa fa-building',
                        route: '/administration/codelist/cargo-type',
                        permissions: [
                            AdministrationPermissions.Settings.view
                        ]
                    }
                ]
            }
        ]
    }
];

<ng-container *ngIf="options.propertyGroups?.length > 0">
    <div class="row" *ngFor="let properties of options.propertyGroups; let i = index" [ngClass]="{ 'mt-2': i > 0 }">
        <div [ngClass]="property.colSize ? 'col-' + property.colSize : 'col'" *ngFor="let property of getDisplayedProperties(properties)">
            <app-control
                *ngIf="isVisible(property)"
                [(ngModel)]="model[getName(property)]" [entity]="" property="model[getName(property)]"
                [property]="getName(property)"
                [entity]="model"
                [label]="property.label"
                [type]="property.type"
                [options]="property.options"
                [textField]="property.textField || 'label'"
                [valueField]="property.valueField || 'value'"
                [valuePrimitive]="property.valuePrimitive"
                [time]="property.time || false"
                [isDisabled]="isDisabled(property)"
                [format]="property.format">
            </app-control>
        </div>
    </div>
    <div class="row mb-4 mt-3">
        <div class="col">
            <div class="float-right">
    
                <button kendoButton class="btn btn-xs btn-outline-success btn-rounded custom-btn btn-search" (click)="add()">
                    <fa-icon class="ml-1" [icon]="['fas', 'search']"></fa-icon> {{'Add'|translate}}
                </button>
&nbsp;
                <button kendoButton class="btn btn-xs btn-outline-danger btn-rounded custom-btn" (click)="clear()">
                    <fa-icon class="ml-1" icon="ban"></fa-icon>
                </button>

            </div>
        </div>
    </div>
</ng-container>
<kendo-grid-column #removeColumn title="" width="40px">
    <ng-template kendoGridCellTemplate let-row>
        <button class="btn btn-danger btn-xs" (click)="remove(row)">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-template>
</kendo-grid-column>

<div class="action-bar" [ngClass]="{'fixed minimized': collapsed}" *ngIf="hasAnyVisibleGroup()">
    <div class="action-bar1">
    <div class="action-bar-group toggle" *ngIf="collapsible" (click)="toggleActionBarVisibility()">
        <!-- <div class="group-label" style="height: 19px;">
            <fa-icon [icon]="['fas', !collapsed ? 'chevron-circle-up' : 'chevron-circle-down']"></fa-icon>
        </div>   -->
        <div class="action-bar-item">
            <div class="btn arrow" [ngClass]="{'hidden': collapsed}">
            </div>
        </div>
    </div>
    <ng-template ngFor let-actionGroup [ngForOf]="items">
        <div class="action-bar-group" *ngIf="isVisible(actionGroup)">
          <!--  <div class="group-label" placement="{{tooltipPlacement}}" ngbTooltip="{{actionGroup.label}}" [openDelay]="tooltipOpenDelay">{{actionGroup.label}}</div> -->
            <ng-template ngFor let-item [ngForOf]="actionGroup.items">
                <div class="action-bar-item" *ngIf="label(item) && !item.options">
                    <button class="btn" [disabled]="isDisabled(item, actionGroup)" *ngIf="isVisible(item)" (click)="onClick(item, actionGroup)" [ngClass]="{'hidden': collapsed}"
                            placement="{{tooltipPlacement}}" ngbTooltip="{{label(item)}}" [openDelay]="tooltipOpenDelay">
                        <fa-icon *ngIf="!collapsed" [icon]="['fas', icon(item)]" [ngClass]="item.style"></fa-icon>
                        <div *ngIf="!collapsed" class="btn-label" [ngClass]="item.style" >{{label(item) | translate}}</div>
                    </button>
                </div>
                <div class="action-bar-item" *ngIf="item.options">
                    <div ngbDropdown>
                        <button style="line-height: 20px;"
                                class="btn" type="button"
                                ngbDropdownToggle
                                placement="{{tooltipPlacement}}" ngbTooltip="{{label(item)}}"
                                [openDelay]="tooltipOpenDelay"
                                *ngIf="!collapsed" [ngClass]="{'hidden': collapsed}">
                            <div *ngIf="!collapsed" style="margin-top: 10px;">
                                <fa-icon [icon]="['fas', 'undo']"></fa-icon>
                            </div>
                            <div style="margin-bottom: 9px;"></div>
                            <span *ngIf="!collapsed" class="btn-label">Revert moves</span>
                        </button>
                        <div ngbDropdownMenu>
                            <ng-template ngFor let-option [ngForOf]="item.options">
                                    <button [disabled]="isDisabled(option, actionGroup)" *ngIf="isVisible(item)" ngbDropdownItem (click)="onClick(option, actionGroup)" >
                                        <fa-icon *ngIf="icon(option)" [icon]="['fas', icon(option)]"></fa-icon>
                                        {{label(option) | translate}}
                                    </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="small-buttons-container" *ngIf="item.smallItems"> 
                    <ng-template ngFor let-smallItem [ngForOf]="item.smallItems">
                        <div class="action-bar-item small">
                            <button class="btn"
                                    [disabled]="isDisabled(smallItem, actionGroup)"
                                    (click)="onClick(smallItem, actionGroup)"
                                    placement="{{tooltipPlacement}}" ngbTooltip="{{label(smallItem)}}"
                                    [openDelay]="tooltipOpenDelay"
                                    *ngIf="isVisible(smallItem) && !collapsed"
                                    [ngClass]="{'hidden': collapsed}">
                                <span *ngIf="!collapsed">
                                    <fa-icon [icon]="['fas', icon(smallItem)]"></fa-icon>
                                </span>
                                <span *ngIf="!collapsed" class="btn-label">{{label(smallItem)|translate}}</span>
                            </button>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </ng-template>
    <div style="clear: both"></div>
</div>
</div>
<div *ngIf="hasAnyVisibleGroup()" [ngStyle]="{'margin-bottom': (collapsed ? 20 : 95) + 'px'}"></div>

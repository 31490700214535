<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="card card-border-top mt-3 col-12 ">
            <div class="card-header mt-3 col-12">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 title">
                        <h5>{{ fullName }}</h5>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <button class="btn btn-primary float-right" type="submit" [isDisabled]="isSaved" (click)="save()">
                            <fa-icon [icon]=" ['fas', 'save' ]"></fa-icon> {{ 'Save' | translate }}
                        </button>
                        <button class="btn btn-info float-right" type="button" (click)="goBack()"
                            style="margin-right: 5px;">
                            <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> {{ 'Back' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row" [isDisabled]="isSaved">
                    <div class="col-12 pt-1 info-helper-text">
                        {{
                        'To create a PCS user fill out the form below. User name is equeal as your valid email address.' | translate
                        }}
                    </div>

                    <ul>
                        <li class="col-12 pt-1 info-helper-text">
                            {{ 'password should be unique, do not use the same password for multiple systems' | translate }}
                        </li>
                        <li class="col-12 pt-1 info-helper-text">
                            {{ 'password should be complex, do not use name, surname, pcs, birthdays or 123 in your password' | translate }}
                        </li>
                        <li class="col-12 pt-1 info-helper-text">
                            {{
                            'password should be made out of 2 or more unrelated words or random characters, more meaning a safer password' | translate
                            }}
                        </li>
                        <li class="col-12 pt-1 info-helper-text">
                            {{ 'phone number should be in format +385**********, eg. +385910000000' | translate }}
                        </li>
                    </ul>

                    <div class="col-12 pt-1 info-helper-text">
                        {{
                        'After form is filled out and submitted, a form with your registration data will be sent to your e-mail. Fill out the received form and submit for signature to the authorized person which represents your organization as well as PCS administrator.' | translate
                        }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <app-control [label]="'Email' | translate" [(ngModel)]="userName" [entity]="" property="userName" [isDisabled]="isSaved">
                        </app-control>
                        <div *ngIf="isEmailInvalid(userName)"><span class="label label-danger">{{ 'You have entered an invalid email address!' | translate}}</span></div>
                    </div>
                    <div class="col-sm-3">
                        <app-control [label]="'Confirm email' | translate" [(ngModel)]="email" [entity]="" property="email" [isDisabled]="isSaved">
                        </app-control>
                        <div *ngIf="isEmailInvalid(email)"><span class="label label-danger">{{ 'You have entered an invalid email address!' | translate}}</span></div>
                    </div>

                    <div class="col-sm-3">
                        <app-control [label]="'Organization' | translate" [(ngModel)]="organizationId" [entity]="" property="organizationId" type="codelist"
                            [fetch]="OrganizationsList" [isDisabled]="isSaved"></app-control>
                        <div *ngIf="!organizationId"><span class="label label-danger">{{ 'Select your organization from the list!' | translate}}</span></div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <app-control [label]="'Full Name' | translate" [(ngModel)]="fullName" [entity]="" property="fullName" [isDisabled]="isSaved">
                        </app-control>
                        <div *ngIf="isFullNameInvalid(fullName)"><span class="label label-danger">{{ 'Fullname can only use letters, minimum length is 4 characters!' | translate}}</span></div>
                    </div>

                    <div class="col-sm-3">
                        <app-control [label]="'Phone Number' | translate" [(ngModel)]="phoneNumber" [entity]="" property="phoneNumber"
                            [isDisabled]="isSaved">
                        </app-control>
                        <div *ngIf="isPhoneNumberInvalid(phoneNumber)"><span class="label label-danger">{{ 'Enter your phone number!' | translate}}</span></div>
                    </div>

                    <div class="col-sm-3">
                        <app-control [label]="'Language' | translate" [(ngModel)]="languageId" [entity]="" property="languageId" type="codelist"
                            [isDisabled]="isSaved" [fetch]="LanguagesList" (ngModelChange)='onLanguageChanged($event)' >
                        </app-control>
                        <div *ngIf="!languageId"><span class="label label-danger">{{ 'Select your language from the list!' | translate}}</span></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <app-control [label]="'Password' | translate" [(ngModel)]="password" [entity]="" property="password" min="6" max="8"
                            type="password" [isDisabled]="isSaved">
                        </app-control>
                        <div *ngIf="isPasswordInvalid(password)"><span class="label label-danger">{{ 'Password should not be empty! Password should contain minimal 8 characters, at least one letter, one number and one special character e.g. "!"' | translate}}</span></div>
                    </div>

                    <div class="col-sm-3">
                        <app-control [label]="'Confirm Password' | translate" [(ngModel)]="confirmPassword" [entity]="" property="confirmPassword"
                            type="password" [isDisabled]="isSaved">
                        </app-control>
                        <div *ngIf="isPasswordInvalid(confirmPassword)"><span class="label label-danger">{{ 'Password should not be empty! Password should contain minimal 8 characters, at least one letter, one number and one special character e.g. "!"' | translate}}</span></div>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="organizationId==-1">
                    <div class="col-sm-12">
                        <h5 class="h5">{{'Organization data' | translate}}</h5>
                    </div>
                    <div class="col-sm-3">
                        <app-control [label]="'Name' | translate" [(ngModel)]="organizationName" [entity]="" property="organizationName" min="3" max="50"
                           [isDisabled]="isSaved" (ngModelChange)="onOrganizationNameChanged($event)">
                        </app-control>
                        <div *ngIf="isOrganizationNameInvalid(organizationName)"><span class="label label-danger">{{ 'Organization name should contain minimal 4 characters!' | translate}}</span></div>
                    </div>
                    <div class="col-sm-3">
                        <app-control [label]="'Code' | translate" [(ngModel)]="organizationCode" [entity]="" property="organizationCode" min="3" max="20"
                           [isDisabled]="true">
                        </app-control>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-loader>
import { Component, OnInit } from '@angular/core';
import { NgbTabset, NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-card-tabset',
    template: `
    <div class="card">
    <div class="">
      <ul [class]="'nav nav-tabs  nav-' + type + (orientation == 'horizontal'?  ' ' + justifyClass : ' flex-column')" role="tablist">
        <li class="nav-item" *ngFor="let tab of tabs">
          <a [id]="tab.id" class="nav-link" [class.active]="tab.id === activeId" [class.disabled]="tab.disabled" href (click)="!!select(tab.id)"
            role="tab" [attr.tabindex]="(tab.disabled ? '-1': undefined)" [attr.aria-controls]="(!destroyOnHide || tab.id === activeId ? tab.id + '-panel' : null)"
            [attr.aria-expanded]="tab.id === activeId" [attr.aria-disabled]="tab.disabled">
            {{tab.title}}
            <ng-template [ngTemplateOutlet]="tab.titleTpl?.templateRef"></ng-template>
          </a>
        </li>
      </ul>
    </div>
    <div class="pt-1" style="width:100%¸;padding-top: 0rem !important;">
      <div class="tab-content">
        <ng-template ngFor let-tab [ngForOf]="tabs">
          <div class="tab-pane {{tab.id === activeId ? 'active' : null}}" *ngIf="!destroyOnHide || tab.id === activeId" role="tabpanel"
            [attr.aria-labelledby]="tab.id" id="{{tab.id}}-panel" [attr.aria-expanded]="tab.id === activeId">
            <ng-template [ngTemplateOutlet]="tab.contentTpl?.templateRef"></ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

    `,
    styleUrls: [
      './card-tabset.component.scss'
  ]
})
export class CardTabsetComponent extends NgbTabset  {
    constructor(config: NgbTabsetConfig) {
        super(config);
    }
}

// tslint:disable:no-access-missing-member
import { Component, ChangeDetectorRef, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { PagerElementComponent, PagerContextService } from '@progress/kendo-angular-grid';
import { PagerContextChanges } from '@progress/kendo-angular-grid/dist/es2015/pager/pager-context.service';

/**
 * Displays a drop-down list for the page size selection ([see example]({% slug paging_grid %}#toc-pager-templates)).
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-pager-page-sizes',
    template: `
        <kendo-dropdownlist #select
            class="app-pager-page-sizes ml-2 mr-1"
            style="width: 80px" [data]="[10, 20, 50]"
            (valueChange)="pageSizeChange(select.value)"
            [attr.aria-label]="textFor('pagerItemsPerPage')"
            [data]="pageSizes"
            [value]="pageSize">
        </kendo-dropdownlist>
        {{ textFor('pagerItemsPerPage') }}
    `
})
export class PagerPageSizesComponent extends PagerElementComponent {

    /**
     * The page sizes that will be displayed.
     *
     * @memberOf PagerPageSizesComponent
     */
    @Input() public pageSizes: Array<number>;


    /**
     * @hidden
     *
     * @memberOf PagerPageSizesComponent
     */
    public get showInitialPageSize(): boolean {
        return (<Array<number>>this.pageSizes)
            .filter(num => num === Number(this.pageSize))
            .length === 0;
    }

    constructor(
        localization: LocalizationService,
        cd: ChangeDetectorRef,
        protected pagerContext: PagerContextService
    ) {
        super(localization, pagerContext, cd);
    }

    /**
     * @hidden
     *
     * @memberOf PagerPageSizesComponent
     */
    public pageSizeChange(value: any): void {
        this.pageSize = parseInt(<any>value, 10);
        this.pagerContext.changePageSize(this.pageSize);
    }

    protected onChanges({ total, skip, pageSize }: PagerContextChanges): void {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }
}

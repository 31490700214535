import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
    template: ''
})
export class LogoutComponent {
    constructor(private router: Router, private userService: UserService) {
        this.userService.logout().then(() => {
            return this.router.navigate(['/login']);
        });
    }
}

<nav class="navbar fixed-top navbar-toggleable-md navbar-dark nav-shadow nav-background">
    <div>
        <span *ngIf="user" class="toggle-menu mr-3" (click)="onToggleSidebar()">
            <i aria-hidden="true" class="fa fa-bars"></i>
        </span>

        <h3 class="navbar-brand" routerLink="/home">
            <img src="../../../../assets/img/logo_novi1.png">
            PCS - Lučka Uprava Ploče
        </h3>
    </div>
    <div *ngIf="user" style="margin-left: auto;" display="dynamic">
        <a [routerLink]="['/administration/user/new']" style="color: #ffff;" *ngIf="isAdm()">
            <fa-icon [icon]="['far', 'user']" style="color: #ffff; font-size: 15px !important;"></fa-icon>
            {{userCount}}
        </a>
    </div>
    <div style="margin-left: 0.5%;" display="dynamic">
        <div class="languageDropdown">
            <div ngbDropdown placement="bottom-right">
                <button class="btn btn-link" id="languageDropdown" ngbDropdownToggle>
                    <span class="name ml-1 mr-1"><i class="fa fa-flag"></i><span style="padding-left: 5px !important;"
                            translate>Language</span></span>
                </button>
                <div ngbDropdownMenu aria-labelledby="languageDropdown">
                    <button class="dropdown-item" (click)="selectLanguage('en')"><span
                            class="flag-icon flag-icon-gb"></span>{{'EN'|translate}}</button>
                    <button class="dropdown-item" (click)="selectLanguage('hr')"><span
                            class="flag-icon flag-icon-hr"></span>{{'HR'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="user">
        <div ngbDropdown display="dynamic" placement="bottom-right" class="d-inline-block user">
            <button class="btn btn-link" id="dropdownBasic1" ngbDropdownToggle>
                <span class="name ml-1 mr-1"><i class="fa fa-user"></i> {{ user.fullName ? user.fullName : user.userName
                    }}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <a class="dropdown-item" [routerLink]="['/administration/user/profile']"><i class="fa fa-user"></i>
                    {{'Account'|translate}}</a>
                <!-- <a class="dropdown-item" [routerLink]="['/administration/settings']"><i class="fa fa-cog"></i> {{'Settings'|translate}}</a>  -->
                <!-- <button class="dropdown-item" (click)="settings()" translate>Settings</button>-->
                <div tabindex="-1" class="dropdown-divider"></div>
                <button class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out"></i> {{'Sign
                    Out'|translate}}</button>
            </div>
        </div>
    </div>
</nav>
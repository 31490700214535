import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '@common/services/dialog.service';
import { forkJoin, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ToastyService, ToastyConfig } from '@cime/ngx-toasty';
import { th } from 'date-fns/locale';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { translate } from '@angular/localize/src/utils';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss'],

})
export class UserCreateComponent implements OnInit {
    id: number;
    userName: string;
    password: string;
    isBusy = false;
    isSaved = false;
    isOrganizationSaved = false;
    errorForm: boolean;
    languages: any[];
    fullName: string;
    organizations: any[];
    confirmPassword: string;
    email: string;
    phoneNumber: string;

    organizationId: number;
    languageId: string;

    organizationName: string;
    organizationCode: string;

    constructor(
        private userService: UserService,
        private translationService: TranslateService,
        private router: Router,
        private httpClient: HttpClient,
        private dialogService: DialogService,
        private toastyService: ToastyService,
        private location: Location
    ) {
        this.languages = [{ languageId: 'HR', name: 'Croatian' }, { languageId: 'EN', name: 'English' }];
        this.id = 0;
        this.userName = '';
        this.password = '';
        this.fullName = '';
        this.confirmPassword = '';
        this.email = '';
        this.phoneNumber = '';
        this.organizationName = '';
        this.organizationCode = '';

        this.errorForm = true;
    }

    ngOnInit(): void {
        forkJoin({
            organizations: this.userService.getOrganizations()
        })
            .pipe(
                catchError((err) => {
                    this.isBusy = true;
                    return throwError(err);
                })
            )
            .subscribe(({ organizations }) => {
                this.organizations = organizations;
                organizations.push({Id: -1, Name: this.translationService.instant('Other')});
                this.isBusy = false;
            });
    }

    OrganizationsList = () => {
        return new Promise((resolve, reject) => {
            const res: any = this.organizations;
            return resolve(_.map(res, org => ({
                value: org.Id,
                label: org.Name
            })));
        });
    }

    LanguagesList = () => {
        return new Promise((resolve, reject) => {
            const res = this.languages;
            return resolve(_.map(res, lan => ({
                value: lan.languageId,
                label: lan.name
            })));
        });
    }

    save() {
        if (this.isEmailInvalid(this.email) || this.isEmailInvalid(this.userName) || (this.email !== this.userName) || this.organizationId == null
        || this.isFullNameInvalid(this.fullName) || this.isPhoneNumberInvalid(this.phoneNumber)
        || this.isPasswordInvalid(this.password) || this.password !== this.confirmPassword) {
            this.toastyService.error(this.translationService.instant('Check registration data'));
            return;
        }

        const userSaveData = {
            id: this.id,
            userName: this.userName,
            fullName: this.fullName,
            organizationId: this.organizationId,
            email: this.email,
            phoneNumber: this.phoneNumber,
            languageId: this.languageId,
            password: this.password,
            confirmPassword: this.confirmPassword,
            active: false
        };

        if (this.organizationId === -1) {
            if (this.isOrganizationNameInvalid(this.organizationName) || this.isOrganizationCodeInvalid(this.organizationCode)) {
                this.toastyService.error(this.translationService.instant('Check organization registration data'));
                return;
            }

            const organizationSaveData = {
                organizationCode: this.organizationCode,
                organizationName: this.organizationName
            };
            this.userService.isOrganizationAlreadyAdded(organizationSaveData.organizationName).then((value) => {
                if (!value) {
                    this.userService
                        .createOrganization(organizationSaveData)
                        .pipe(
                            first(),
                            catchError((err) => throwError(this.toastyService.error(err)))
                        )
                        .subscribe((data) => {
                            this.isOrganizationSaved = true;
                            this.toastyService.success(this.translationService.instant('Adding new organization successfully completed'));
                            userSaveData.organizationId = data.Id;
                            this.createUserFromFormData(userSaveData);
                        });
                } else {
                    this.toastyService.error(this.translationService.instant('The organization is already present in the system'));
                }
            }).catch((errorMessage) => {
                this.toastyService.error(errorMessage);
            });
        } else {
            this.createUserFromFormData(userSaveData);
        }
    }

    private createUserFromFormData(userSaveData: { id: number; userName: string; fullName: string; organizationId: number; email: string; phoneNumber: string; languageId: string; password: string; confirmPassword: string; active: boolean; }) {
        this.userService.isEmailUniq(userSaveData.email).then((value) => {
            if (!value) {
                this.userService
                    .createUser(userSaveData)
                    .pipe(
                        first(),
                        catchError((err) => throwError(this.toastyService.error(err)))
                    )
                    .subscribe((data) => {
                        this.isSaved = true;
                        this.toastyService.success(this.translationService.instant('Adding new user successfully completed'));
                    });
            } else {
                this.toastyService.error(this.translationService.instant('The email address is already registered, enter a valid email address'));
            }
        }).catch((errorMessage) => {
            this.toastyService.error(errorMessage);
        });
    }

    public onLanguageChanged($event: string) {
        if ($event !== undefined && this.translationService.currentLang !== $event.toLowerCase()) {
            this.translationService.use($event.toLowerCase());
        }
    }

    public onOrganizationNameChanged($event: string) {
        if ($event !== undefined) {
            this.organizationCode = $event.toUpperCase().replace(' ', '');
        }
    }

    isUserNameInvalid(userName: string) {
        const letters = /^[a-zšđčćž0-9_-]{8,15}$/;
        if (userName.match(letters)) {
            this.errorForm = true;
            return false;
        } else {
            this.errorForm = false;
            return true;
        }
    }

    isFullNameInvalid(userName: string) {
        const letters = /^[a-zšđčćžA-ZŠĐČĆŽ](\s?[a-zšđčćžA-ZŠĐČĆŽ0-9]){3,29}$/;
        if (userName.match(letters)) {
            this.errorForm = true;
            return false;
        } else {
            this.errorForm = false;
            return true;
        }
    }

    isPasswordInvalid(password: string) {
        const paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zšđčćžA-ZŠĐČĆŽ0-9!@#$%^&*]{7,15}$/;
        if (password.match(paswd)) {
            this.errorForm = true;
            return false;
        } else {
            this.errorForm = false;
            return true;
        }
    }

    isEmailInvalid(mail: string) {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (mail.match(mailformat)) {
            this.errorForm = true;
            return false;
        } else {
            this.errorForm = false;
            return true;
        }
    }

    isPhoneNumberInvalid(phoneNumber: string) {
        const phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (phoneNumber.match(phoneno)) {
            this.errorForm = true;
            return false;
        } else {
            this.errorForm = false;
            return true;
        }
    }

    isOrganizationNameInvalid(organizationName: string) {
        const letters = /^[a-zšđčćžA-ZŠĐČĆŽ0-9](\s?[a-zšđčćžA-ZŠĐČĆŽ0-9.]){2,49}$/;
        if (organizationName.match(letters)) {
            this.errorForm = true;
            return false;
        } else {
            this.errorForm = false;
            return true;
        }
    }

    isOrganizationCodeInvalid(organizationCode: string) {
        const letters = /^[a-zšđčćžA-ZŠĐČĆŽ0-9](\s?[a-zšđčćžA-ZŠĐČĆŽ0-9.]){2,49}$/;
        if (organizationCode.match(letters)) {
            this.errorForm = true;
            return false;
        } else {
            this.errorForm = false;
            return true;
        }
    }

    goBack() {
        this.location.back();
    }
}

import { Component, OnInit } from '@angular/core';
import { EntitiesErrors } from '@common/classes/entities-errors';
import { EntityError } from '@cime/breeze-client';

@Component({
    selector: 'app-entities-errors',
    templateUrl: './entities-errors.component.html',
    styleUrls: ['./entities-errors.component.scss']
})
export class EntitiesErrorsComponent implements OnInit {

    errors: EntityError[];

    constructor(errors: EntitiesErrors) {
        this.errors = errors.errors;
    }

    ngOnInit() {
    }

}

<div class="fixed-background" [ngStyle]="{'background-image': (chosenImage) ? 'url(' + chosenImage + ')' : '' }">

	<div class="login-wrap">
		<div class="login-html">

			<input id="tab-1" type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab">{{'Sign In'
				|translate}}</label>
			<input id="tab-2" type="radio" name="tab" class="sign-up"><label for="tab-2" class="tab"></label>
			<div class="login-form">
				<div class="sign-in-htm">

					<form *ngIf="!showChangePassword">
						<div class="alert alert-danger" role="alert" *ngIf="errorMessage" [textContent]="errorMessage">
						</div>
						<div class="group">
							<label for="user" class="label">{{'Username' |translate}}</label>
							<div class="input-group-prepend">
								<span class="input-group-text"><i class="fa fa-user"></i></span>
								<input type="text" id="username" name="username"
									[ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="username" class="input"
									placeholder="Username" required autofocus>
							</div>

						</div>
						<div class="group">
							<label for="pass" class="label">{{'Password' |translate}}</label>
							<div class="input-group-prepend">
								<span class="input-group-text"><i class="fa fa-key"></i></span>
								<input type="password" id="password" name="password"
									[ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="password" class="input"
									placeholder="Password" required>
							</div>
						</div>
						<!--
					<div class="group">
						<input id="check" type="checkbox" class="check" checked>
						<label for="check"><span class="icon"></span>Keep me Signed in</label>
					</div> -->
						<div class="group">
							<input type="submit" class="button" value="{{'Sign in' |translate}}" (click)="signIn()"
								[disabled]="isBusy || !username || !password">
						</div>
						<div class="hr"></div>
						<div class="group">
							<input type="submit" class="button" value="{{'Sign Up' |translate}}" (click)="signUp()"
								[disabled]="isBusy">
						</div>
						<!--
					<div class="foot-lnk">
						<a href="#forgot">Forgot Password?</a>
					</div>	 -->
						<div style="text-align: center;">
							<img src="./../../../../assets/img/ADRIPASS_sm.png"
								style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
							<img src="./../../../../assets/img/adriaticIpa_sm.jpg"
								style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
								<img src="./../../../../assets/img/EU-logo_sm.png"
								style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
							<img src="./../../../../assets/img/interreg-italy-croatia_sm.jpg"
								style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
							
							<img src="./../../../../assets/img/MultiAPPRO_logo_sm.jpg"
								style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
							<img src="./../../../../assets/img/PROMARES_sm.jpg"
								style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
							<img src="./../../../../assets/img/INTESA_sm.png"
								style="background-color: #fff;margin-top: 20px;">
						</div>

					</form>

					<form *ngIf="showChangePassword">
						<label for="tab-1" class="tab">Potrebno je ažurirati/promijeniti lozinku.</label>
						<div class="alert alert-danger" role="alert" *ngIf="errorMessage" [textContent]="errorMessage">
						</div>
						<div class="group">
							<label for="user" class="label">{{'New password'|translate}}</label>
							<div class="input-group-prepend">
								<span class="input-group-text">
									<fa-icon icon="key"></fa-icon>
								</span>
								<input type="password" name="newPassword" [ngClass]="{'isInvalid': !!errorMessage}"
									[(ngModel)]="newPassword" class="input" required autofocus>
							</div>

						</div>
						<div class="group">
							<label for="pass" class="label">{{'Repeat new password'|translate}}</label>
							<div class="input-group-prepend">
								<span class="input-group-text">
									<fa-icon icon="key"></fa-icon>
								</span>
								<input type="password" name="repeatNewPassword"
									[ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="repeatNewPassword"
									class="input" required>
							</div>
						</div>
						<div class="group">
							<input type="submit" class="button" value="{{'Change password' |translate}}"
								(click)="changePassword()" [disabled]="isBusy || !newPassword || !repeatNewPassword">
						</div>
						<div class="hr"></div>
						<!--
					<div class="foot-lnk">
						<a href="#forgot">Forgot Password?</a>
					</div>	 -->
						<div style="text-align: center;">
							<img src="./../../../../assets/img/ADRIPASS_sm.png"
								style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
							<img src="./../../../../assets/img/adriaticIpa_sm.jpg"
								style="background-color: #fff;margin-top: 20px;">
							<img src="./../../../../assets/img/interreg-italy-croatia_sm.jpg"
								style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
							<img src="./../../../../assets/img/EU-logo_sm.png"
								style="background-color: #fff;margin-top: 20px;">
						</div>
					</form>

				</div>
				<!--
			<div class="sign-up-htm">
				<div class="group">
					<label for="user" class="label">Name</label>
					<input id="user" type="text" class="input">
				</div>
				<div class="group">
					<label for="user" class="label">Surname</label>
					<input id="user" type="text" class="input">
				</div>
				<div class="group">
					<label for="user" class="label">Username</label>
					<input id="user" type="text" class="input">
				</div>
				<div class="group">
					<label for="pass" class="label">Password</label>
					<input id="pass" type="password" class="input" data-type="password">
				</div>
				<div class="group">
					<label for="pass" class="label">Repeat Password</label>
					<input id="pass" type="password" class="input" data-type="password">
				</div>
				<div class="group">
					<label for="pass" class="label">Email Address</label>
					<input id="pass" type="text" class="input">
				</div>
				<div class="group">
					<input type="submit" class="button" value="Sign Up">
				</div>
				<div class="hr"></div>
				<div class="hr"></div>
				<div class="foot-lnk">
					<a href="#forgot">Forgot Password?</a>
				</div>	
				<div style="text-align: center;"> 
					<img src="./../../../../assets/img/ADRIPASS_sm.png" style="background-color: #fff;margin-top: 20px;margin-right: 20px;" >
					<img src="./../../../../assets/img/adriaticIpa_sm.jpg" style="background-color: #fff;margin-top: 20px;" >
					<img src="./../../../../assets/img/interreg-italy-croatia_sm.jpg" style="background-color: #fff;margin-top: 20px;margin-right: 20px;" >
					<img src="./../../../../assets/img/EU-logo_sm.png" style="background-color: #fff;margin-top: 20px;" >
					</div>	
			</div>	-->
			</div>
		</div>
	</div>
</div>
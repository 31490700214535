import { Injectable, Injector, ComponentFactoryResolver } from '@angular/core';
import { NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../components/confirm-dialog.component';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { WarningDialogComponent } from '@common/components/warning-dialog.component';
import { DialogFormComponent, DialogFormOptions } from '@common/components/dialog-form/dialog-form.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private modalService: NgbModal,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector) {
    }

    public confirm(title: string, message: string): Promise<any> {

        const dialogRef = this.modalService.open(ConfirmDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.result;
    }

    open(content: any, options: NgbModalOptions, createdCallback?: (NgbModalRef) => void) {
        const modal = this.modalService.open(content, options);
        if (createdCallback) {
            createdCallback(modal);
        }

        return modal.result
            .catch(reason => {
                if (!reason || reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    return;
                }

                throw reason;
            });
    }

    public form(options: DialogFormOptions, modalOptions?: NgbModalOptions) {
        const dialogRef = this.modalService.open(DialogFormComponent, modalOptions);
        dialogRef.componentInstance.initialize(options, this.componentFactoryResolver, this.injector);

        return dialogRef.result;
    }

    public changes(title: string, message: string): Promise<any> {

        const dialogRef = this.modalService.open(ChangesDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.result;
    }

    public warning(title: string, message: string): Promise<any> {

        const dialogRef = this.modalService.open(WarningDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.result;
    }
}

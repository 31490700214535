import { Pipe, PipeTransform } from '@angular/core';
import { BreezeEntity } from '@common/classes/breeze-entity';
import * as _ from 'lodash';
import { EntityManager, EntityQuery, NavigationProperty } from '@cime/breeze-client';

@Pipe({
    name: 'codelist'
})
export class CodelistPipe implements PipeTransform {

    constructor(private entityManager: EntityManager) {
    }

    transform(value: BreezeEntity | string, ...args): Promise<string> {
        if (_.isString(args[0])) {
            if (!value) {
                return Promise.resolve('-');
            }

            return new EntityQuery('CodeList')
                .using(this.entityManager)
                .withParameters({
                    $method: 'POST',
                    $data: {
                        name: args[0],
                        selectedIds: [value],
                        filter: value,
                        take: 1
                    }
                }).execute().then(data => {
                    return this.getText(data.results[0], value, args[0]);
                });
        }

        const entity: BreezeEntity = args[0];
        const property: string = args[1];
        const { navigationProperty, dataProperty } = this.getProperties(entity, property);
        if (!navigationProperty || !navigationProperty.entityType) {
            throw new Error(`Property ${property} does not exist on ${entity.entityType.shortName} or is not NavigationProperty`);
        }

        if (!entity || !entity.entityAspect) {
            return Promise.resolve('-');
        }

        const entityManager: EntityManager = entity.entityAspect.entityManager;
        const syntheticPropertyName = dataProperty.name;
        const relationEntityType = navigationProperty.entityType.shortName;
        const id = entity[syntheticPropertyName];
        const query = new EntityQuery('CodeList')
            .withParameters({
                $method: 'POST',
                $data: {
                    name: relationEntityType,
                    selectedIds: [id],
                    filter: id
                }
            })
            .take(1);

        return entityManager.executeQuery(query)
            .then(response => {
                const item = _.find(response.results, x => x.id === entity[syntheticPropertyName] || x.code === entity[syntheticPropertyName]);

                return this.getText(item, id, relationEntityType);
            });
    }

    private getText(item, id, codelist) {
        if (!item) {
            if (id) {
                console.error(`${id} not found in codelist ${codelist}`);
            }

            return '-';
        }

        return `${item.customText || ((item.code || item.id) + '-' + item.name)}`;
    }

    private getProperties(entity: BreezeEntity, propertyName: string) {
        const property = entity.entityType.getProperty(propertyName) as any;
        if (property instanceof NavigationProperty) {
            return {
                navigationProperty: property,
                dataProperty: _.find(entity.entityType.foreignKeyProperties, fk => fk.relatedNavigationProperty === property)
            };
        } else {
            return {
                navigationProperty: _.find(entity.entityType.foreignKeyProperties, fk => fk.name === propertyName).relatedNavigationProperty,
                dataProperty: property
            };
        }
    }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router, NavigationStart, NavigationError, NavigationEnd, NavigationCancel } from '@angular/router';
import { NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastyModule, ToastyService, ToastyConfig } from '@cime/ngx-toasty';
import { SlimLoadingBarModule, SlimLoadingBarService } from '@cime/ngx-slim-loading-bar';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DataServiceWebApiAdapter } from '@cime/breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from '@cime/breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from '@cime/breeze-client/adapter-uri-builder-json';
import { AjaxHttpClientAdapter } from '@cime/breeze-client/adapter-ajax-httpclient';
import { config, EntityManager, NamingConvention } from '@cime/breeze-client';
import { FluentValidators } from './common/breeze-validators';

import { CommonModule } from './common/common.module';
import { UserService } from './common/services/user.service';

import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ROUTES } from './app.routing';

import localeHr from '@angular/common/locales/hr';
import { registerLocaleData } from '@angular/common';

import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/hr/all';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '../environments/environment';
import { AjaxPostWrapper } from '@cime/breeze-client/adapter-ajax-post';
import { mixinEntityGraph } from '@cime/breeze-client/mixin-get-entity-graph';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { ConsoleContentDirective } from '@common/directives/console-content.directive';
import { DialogErrorHandler } from '@common/handlers/dialog-error-handler';
import { CustomConstructors } from '@common/breeze/custom-constructors';


// the second parameter 'fr' is optional
registerLocaleData(localeHr, 'hr');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

let entityManager: EntityManager;

function setupBreeze() {
    mixinEntityGraph(EntityManager);

    NamingConvention.camelCase.setAsDefault();
    const fv = new FluentValidators();
    fv.registerAsBreezeValidators();

    const breezeMetadataUrl = 'api:///query';
    entityManager = new EntityManager(breezeMetadataUrl);

    CustomConstructors.registerCustomConstructors(entityManager);
}

setupBreeze();

export function getEntityManager() {
    return entityManager;
}

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
          Authorization: 'Bearer ' + localStorage.getItem(environment.accessToken) || null,
    }
  });

  return forward(operation);
});

@NgModule({
    declarations: [
        AppComponent,
        ConsoleContentDirective
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES),

        ApolloModule,
        HttpLinkModule,

        IntlModule,

        ToastyModule.forRoot(),
        NgbModule,
        SlimLoadingBarModule.forRoot(),

        NgxDnDModule.forRoot(),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

        CommonModule
    ],
    exports: [
        BrowserAnimationsModule,
        RouterModule,
        ToastyModule,
        NgbModule,
        SlimLoadingBarModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'hr' },
        {
            provide: EntityManager,
            useFactory: getEntityManager,
        },
        UserService,
        ConsoleWindowService,
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                return {
                    cache: new InMemoryCache(),
                    link: concat(authMiddleware, httpLink.create({
                        uri: environment.graphqlUrl
                    }))
                };
            },
            deps: [HttpLink]
        },
        { provide: ErrorHandler, useClass: DialogErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private userService: UserService,
        private toastyService: ToastyService,
        private toastyConfig: ToastyConfig,
        private router: Router,
        private slimLoadingBarService: SlimLoadingBarService,
        private translate: TranslateService,
        private http: HttpClient,
        private modalConfig: NgbModalConfig
    ) {
        // the order is important
        ModelLibraryBackingStoreAdapter.register();
        UriBuilderJsonAdapter.register();
        AjaxHttpClientAdapter.register(http);
        DataServiceWebApiAdapter.register();
        AjaxPostWrapper.wrapAjax(config.getAdapterInstance('ajax'));

        modalConfig.backdrop = 'static';
        toastyConfig.theme = 'bootstrap';

        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.slimLoadingBarService.start();
            } else if (event instanceof NavigationError) {
                this.slimLoadingBarService.complete();
                console.error(event.error);
            } else if (event instanceof NavigationEnd) {
                this.slimLoadingBarService.complete();
            } else if (event instanceof NavigationCancel) {
                this.slimLoadingBarService.complete();
            }
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use('hr');
    }
}

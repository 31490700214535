<section class="content-header blue">
    <h1 *ngIf="title">
        <i style="margin-left: -10px; font-size: 20px; padding-right: 10px; font-weight: bold;"></i>

        <span class="header-1">{{title}}</span>
    </h1>
    <ul class="breadcrumb" id="breadcrumb">
        <li >
            <a class="active" aria-current="page" [routerLink]="['/']">
                <i class="fa fa-home"></i>
                <span translate>Home</span>
            </a>
        </li>
        <li  *ngFor="let item of breadcrumb">
            <a class="active" aria-current="page" [routerLink]="[item.route]" *ngIf="item.route">
                <i *ngIf="item.icon" class="{{item.icon}}"></i>
                <span translate>{{item.title | async}}</span>
            </a>
            <a  class="last-child active" aria-current="page" *ngIf="!item.route">
                <i *ngIf="item.icon" class="{{item.icon}}"></i>
                <span class="last-child active" translate>{{item.title | async}}</span>
            </a>
        </li>
    </ul>
</section>
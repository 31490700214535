import { environment as base } from './environment.base';
import * as _ from 'lodash';

const baseEnv = _.cloneDeep(base);

export const environment = _.merge(baseEnv, {
    production: true,
    serverUrl: window.location.protocol + '//' + window.location.hostname + '/be',
    apiUrl: window.location.protocol + '//' + window.location.hostname + '/be/api',
    graphqlUrl: window.location.protocol + '//' + window.location.hostname + '/be/graphql',
});

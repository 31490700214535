<app-grid [data]="revisions" class="mb-2">

    <kendo-grid-column title="{{'Action' | translate}}" [sortable]="false">
        <ng-template kendoGridCellTemplate let-item>
            <span>{{item.action}}</span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{'User' | translate}}" [sortable]="false">
        <ng-template kendoGridCellTemplate let-item>
            <span>{{item.user.userName}}</span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{'Date'|translate}}" [sortable]="false">
        <ng-template kendoGridCellTemplate let-item>
            <span>{{item.date | date:'short'}}</span>
        </ng-template>
    </kendo-grid-column>

</app-grid>

import { MessageService } from '@progress/kendo-angular-l10n';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const messages = {
    'kendo.grid.noRecords': 'No hay datos disponibles.'
};

@Injectable({
    providedIn: 'root'
})
export class KendoUiMessageService extends MessageService {

    constructor(private translateService: TranslateService) {
        super();

    }

    public get(key: string): string {
        const translation = this.translateService.instant(key);

        return translation !== key ? translation : undefined;
    }
}

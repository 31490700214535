import { Component, OnInit, Input } from '@angular/core';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { EntityQuery } from '@cime/breeze-client';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-notification-list',
  templateUrl: './user-notification-list.component.html',
  styleUrls: ['./user-notification-list.component.css'],
  providers: [BreezeViewService]
})
export class UserNotificationListComponent {

  isBusy = true;

  breadcrumb;

  translateService;

  notifications: any[] = [];

  @Input() showHeader = true;

  @Input() onlyImportant = false;

  constructor(private http: HttpClient, breezeViewService: BreezeViewService) {
    this.translateService = breezeViewService.translateService;
    this.breadcrumb = [
      {
        icon: 'file',
        title: this.translateService.get('Notice board')
      }
    ];

    const query = EntityQuery.from('UserNotifications');
    breezeViewService.entityManager.executeQuery(query)
        .then((response) => {
          this.notifications = response.results;
          if (this.onlyImportant) {
            this.notifications = _.filter(this.notifications, n => n.important);
          }

          this.isBusy = false;
        }).catch(e => {
      console.error(e);
      this.isBusy = false;
    });
  }

  async download(event, id) {
    event.preventDefault();
    const attachment: any = await this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${id}`).toPromise();
    UploadActionButtonsComponent.openInNewWindow(attachment.name, attachment.content);
  }
}

// tslint:disable: no-access-missing-member
import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input
} from '@angular/core';

import { LocalizationService } from '@progress/kendo-angular-l10n';
import { PagerContextService, PagerElementComponent } from '@progress/kendo-angular-grid';
import { PagerContextChanges } from '@progress/kendo-angular-grid/dist/es2015/pager/pager-context.service';

/**
 * Displays buttons for navigating to the first and to the previous page ([see example]({% slug paging_grid %}#toc-pager-templates)).
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-pager-prev-buttons',
    template: `
        <a  href="#"
            tabindex="-1"
            *ngIf="showFirstButton"
            [title]="textFor('pagerFirstPage')"
            (click)="currentPage !== 1 ? changePage(0) : false"
            [ngClass]="{
                'k-link': true,
                'k-pager-nav': true,
                'k-state-disabled': disabled,
                'k-pager-first': true
            }"
            style="width: auto !important;">
            <span [attr.aria-label]="textFor('pagerFirstPage')"
                [ngClass]="{
                    'k-icon':true,
                    'k-i-seek-w': true
                }">
            </span>
            <span>{{textFor('pagerFirstPage')}}</span>
        </a>
        <a  href="#"
            tabindex="-1"
            [title]="textFor('pagerPreviousPage')"
            (click)="currentPage !== 1 ? changePage(currentPage-2) : false"
            [ngClass]="{
                'k-link': true,
                'k-pager-nav': true,
                'k-state-disabled': disabled,
                '': true
            }"
            style="width: auto !important;">
            <span [attr.aria-label]="textFor('pagerPreviousPage')"
                [ngClass]="{
                    'k-icon':true,
                    'k-i-arrow-w': true
                }">
            </span>
            <span>{{textFor('pagerPreviousPage')}}</span>
        </a>
    `
})
export class PagerPrevButtonsComponent extends PagerElementComponent {

    @Input()
    showFirstButton = false;

    constructor(
        localization: LocalizationService,
        pagerContext: PagerContextService,
        cd: ChangeDetectorRef
    ) {
        super(localization, pagerContext, cd);
    }

    /**
     * @hidden
     *
     * @memberOf PagerPrevButtonsComponent
     */
    public get disabled(): boolean {
        return this.currentPage === 1 || !this.total;
    }

    protected onChanges({ total, skip, pageSize }: PagerContextChanges): void {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }

}

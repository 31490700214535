import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-pager',
    template: `
        <app-pager-prev-buttons></app-pager-prev-buttons>
        <app-pager-next-buttons></app-pager-next-buttons>
        <app-pager-page-sizes [pageSizes]="[10, 20, 50]"></app-pager-page-sizes>
        <app-pager-info></app-pager-info>
    `,
    styles: [
        `:host {
            display: flex;
            width: 100%;
          `
    ]
})
export class PagerComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}

import { Component, OnInit, ComponentFactoryResolver, Injector, StaticProvider, ViewChild, ViewContainerRef, OnDestroy, Type, ComponentRef, AfterViewInit } from '@angular/core';
import { AppControlType } from '../app-control/app-control.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';


export interface DialogFormOptions {
    title: string;
    model?: any;
    cancelText?: string;
    confirmText?: string;
    properties?: FormProperty[];
    contentComponent?: Type<any>;
    contentComponentDataProviders?: StaticProvider[];
    onContentComponentCreated?: (component: any) => void;
}

export interface FormProperty {
    name: string;
    type?: AppControlType;
    label?: string;
    codelist?: string;
    initialValue?: any;
    time?: boolean;
    selectLabel?: Function;
    isDisabled?: boolean;
}

@Component({
    selector: 'app-dialog-form',
    templateUrl: './dialog-form.component.html',
    styleUrls: ['./dialog-form.component.css']
})
export class DialogFormComponent implements OnInit, OnDestroy, AfterViewInit {

    private contentComponentRef: ComponentRef<any>;
    private componentFactoryResolver: ComponentFactoryResolver;
    private injector: Injector;

    model;
    options: DialogFormOptions;
    confirmAction: Function;
    cancelAction: Function;

    @ViewChild('template', { static: false, read: ViewContainerRef }) templateRef: ViewContainerRef;

    constructor(public activeModal: NgbActiveModal) {
    }

    public initialize(options: DialogFormOptions, componentFactoryResolver: ComponentFactoryResolver, injector: Injector) {
        if (!options.properties && !options.contentComponent) {
            throw new Error('properties or contentComponent option has to be set');
        }

        this.options = options;
        this.model = this.options.model || {};
        this.componentFactoryResolver = componentFactoryResolver;
        this.injector = injector;
        // Set initial values
        if (this.options.properties) {
            _.chain(this.options.properties)
                .filter(o => !!o.initialValue)
                .each(o => {
                    this.model[o.name] = o.initialValue;
                })
                .value();
        }
        // hack. If you pass null as selectLabel it does not use the default value.
        _.each(this.options.properties, o => !o.selectLabel
            ? o.selectLabel = (item) => `${item.customText || ((item.code || item.id) + ((item.name) ? (' - ' + item.name) : ''))}`
            : null);
    }

    confirm() {
        this.activeModal.close(this.model);
    }

    cancel() {
        this.activeModal.close(null);
    }

    private loadComponent(component, dataProviders: StaticProvider[]) {
        dataProviders = (dataProviders || []).concat([{ provide: NgbActiveModal, useValue: this.activeModal }]);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const contentInjector = Injector.create({providers: dataProviders || [], parent: this.injector});
        this.contentComponentRef = this.templateRef.createComponent(componentFactory, null, contentInjector);
        if (!this.contentComponentRef.instance.model) {
            this.contentComponentRef.instance.model = this.model;
        } else {
            this.model = this.contentComponentRef.instance.model;
        }

        if (_.isFunction(this.contentComponentRef.instance.confirm)) {
            this.confirmAction = this.contentComponentRef.instance.confirm.bind(this.contentComponentRef.instance);
        }

        if (_.isFunction(this.contentComponentRef.instance.cancel)) {
            this.cancelAction = this.contentComponentRef.instance.cancel.bind(this.contentComponentRef.instance);
        }

        if (_.isFunction(this.options.onContentComponentCreated)) {
            this.options.onContentComponentCreated(this.contentComponentRef.instance);
        }

        this.contentComponentRef.changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (this.options.contentComponent) {
            this.loadComponent(this.options.contentComponent, this.options.contentComponentDataProviders);
        }

        this.confirmAction = this.confirmAction || this.confirm.bind(this);
        this.cancelAction = this.cancelAction || this.cancel.bind(this);
    }

    ngOnDestroy(): void {
        if (this.contentComponentRef) {
            this.contentComponentRef.destroy();
        }
    }

    getName(property: FormProperty) {
        return property.name;
    }
}

import {
    AfterViewInit, ApplicationRef,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input, NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList, Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    ColumnBase,
    DataStateChangeEvent,
    FilterableSettings,
    GridComponent, GroupableSettings,
    ScrollMode,
    SelectionEvent,
    SortSettings,
    DetailTemplateDirective, PagerSettings, SelectableSettings, RowClassArgs, ColumnComponent,  DetailExpandEvent
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, process, SortDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid/dist/es2015/data/data.collection';
import { State } from '@progress/kendo-data-query';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { EntityManager, EntityQuery, FilterQueryOp, Predicate } from '@cime/breeze-client';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { BreezeViewService } from '@common/services/breeze-view.service';

type DataFunction = (state: State) => EntityQuery | any[]; // Promise<any[]>

@Component({
    selector: 'app-grid',
    templateUrl: './app-grid.component.html',
    styleUrls: ['./app-grid.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private rendered = false;
    private deferredColumns: { column: ColumnBase, index?: number }[] = [];
    private dragAndDropSubscription: Subscription;
    public id = _.uniqueId('app-grid-');

    private _isBusy = false;
    get isBusy() {
        return this._isBusy;
    }

    set isBusy(value) {
        if (this._isBusy !== value) {
            this._isBusy = value;
            this.isBusyChange.emit(value);
        }
    }

    internalData: Array<any> | GridDataResult | any;

    get pageSizes() {
        return environment.settings.grid.pageSizes;
    }

    onDataStateChanged: (event?: DataStateChangeEvent) => void;
    private unsubscribe: () => void = _.noop;

    state: State = {
        skip: 0,
        take: environment.settings.grid.pageSize,
        group: null
    };

    @ContentChildren(ColumnBase)
    private columns: QueryList<ColumnBase> = new QueryList<ColumnBase>();

    @ViewChild('grid', {static: true})
    private grid: GridComponent;

    @Input()
    data: Array<any> | GridDataResult | EntityQuery | DataFunction;

    @Input()
    sortable: SortSettings = environment.settings.grid.sortable as SortSettings;

    @Input()
    sort: SortDescriptor[] = [...environment.settings.grid.sort] as SortDescriptor[];

    @Input()
    group: GroupDescriptor[];

    @Input()
    selectable: boolean | SelectableSettings;

    @Input()
    scrollable: ScrollMode = 'scrollable';

    @Input()
    filterable: FilterableSettings;

    @Input()
    groupable: GroupableSettings;

    @Input()
    selectBy = 'id';

    @Input()
    height: number;

    @Output()
    stateChange = new EventEmitter<State>();

    @Output()
    selectionChange = new EventEmitter<SelectionEvent>();

    @Output()
    isBusyChange = new EventEmitter<boolean>();

    @Input()
    selection: any[] = [];

    @Input()
    pageable: PagerSettings = environment.settings.grid.pageable as PagerSettings;

    @Input()
    onRowSwap;

    @Output()
    detailExpand = new EventEmitter<DetailExpandEvent>();

    entityManager;

    @ContentChildren(DetailTemplateDirective)
    public detailTemplateChildren: QueryList<DetailTemplateDirective>;

    public get detailTemplate(): DetailTemplateDirective {
        if (this._customDetailTemplate) {
            return this._customDetailTemplate;
        }

        return this.detailTemplateChildren ? this.detailTemplateChildren.first : undefined;
    }

    private get fields() {
        return this.columns.filter(x => x instanceof ColumnComponent).map((x: ColumnComponent) => x.field).filter(x => x);
    }

    public set detailTemplate(detailTemplate: DetailTemplateDirective) {
        this._customDetailTemplate = detailTemplate;
    }

    private _customDetailTemplate: DetailTemplateDirective;
    private _sub;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private breezeViewService: BreezeViewService,
                private renderer: Renderer2,
                private applicationRef: ApplicationRef,
                private zone: NgZone) {
        this.entityManager = breezeViewService.entityManager;

    }

    ngOnInit() {
        this.state.sort = this.sort;
        this.state.group = this.group;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();

        this.unsubscribe();
        if (this.isDragAndDropEnabled()) {
            this.dragAndDropSubscription.unsubscribe();
        }
    }

    private isDragAndDropEnabled() {
        return this.breezeViewService.isEditMode() &&  _.isFunction(this.onRowSwap);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.data) {
            return;
        }

        this.state.skip = 0;

        if (changes.sort) {
            this.state.sort = this.sort;
        }

        if (changes.group) {
            this.state.group = this.group;
        }

        if (_.isFunction(this.data)) {
            this.onDataStateChanged = this.functionDataStateChanged;
        } else if (_.isArray(this.data)) {
            const array = this.data as any[];
            this.onDataStateChanged = (state) => {
                this.arrayDataStateChanged(array, state);
            };

            this.unsubscribe();

            if ((this.data as any).arrayChanged) {
                this._sub = (this.data as any).arrayChanged.subscribe(() => {
                    this.onDataStateChanged(this.state as any);
                });

                this.unsubscribe = () => {
                    (array as any).arrayChanged.unsubscribe(this._sub);

                    this.unsubscribe = _.noop;
                };
            }
        } else if (this.data instanceof EntityQuery) {
            const query = this.data as EntityQuery;

            this.onDataStateChanged = (state) => {
                this.queryDataStateChanged(query as EntityQuery, state);
            };
        }

        if (this.data) {
            this.onDataStateChanged();
        } else {
            this.internalData = [];
        }
    }

    ngAfterViewInit() {
        if (this.isDragAndDropEnabled()) {
            this.dragAndDropSubscription = this.handleDragAndDrop();
        }
        this.rendered = true;
        this.grid.detailTemplate = this.detailTemplate;

        _.each(this.deferredColumns, args => this.addColumn(args.column, args.index));
        this.deferredColumns.length = 0;

        this.updateColumns();

        this.columns.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updateColumns();
        });
    }

    search() {
        this.onDataStateChanged();
    }

    addColumn(column: ColumnBase, index?: number) {
        if (!this.rendered) {
            this.deferredColumns.push({column: column, index: index});
            return;
        }

        const columns = this.columns.toArray();
        if (index === undefined) {
            columns.push(column);
        } else {
            columns.splice(index, 0, column);
        }

        this.columns.reset(columns);
    }

    private updateColumns() {

        this.grid.columns.reset(this.columns.toArray());
        this.changeDetectorRef.detectChanges();
    }

    private arrayDataStateChanged(array: any[], state: DataStateChangeEvent) {
        if (state) {
            this.state = state;
            this.stateChange.emit(state);
        }

        try {
            this.isBusy = true;

            this.internalData = process(array, {
                ...this.state,
                take: this.pageable === false ? null : this.state.take,
            });

            if (this.isDragAndDropEnabled() && this.dragAndDropSubscription) {
                this.dragAndDropSubscription.unsubscribe();
                this.zone.onStable.pipe(take(1))
                    .subscribe(() => this.dragAndDropSubscription = this.handleDragAndDrop());
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.isBusy = false;
        }
    }

    private functionDataStateChanged(state: DataStateChangeEvent) {
        const result = (this.data as DataFunction)(state);

        if (_.isArray(result)) {
            this.arrayDataStateChanged(result, state);
        } else if (result instanceof EntityQuery) {
            this.queryDataStateChanged(result, state);
        } else {
            throw new Error('Unsupported data type: ' + typeof result);
        }
    }

    private queryDataStateChanged(query: EntityQuery, state: DataStateChangeEvent) {
        if (state) {
            this.state = state;
        }

        try {
            this.isBusy = true;

            query = query.inlineCount(true);
            query = query.skip(this.state.skip);
            query = query.take(this.state.take);

            if (this.state.sort && this.state.sort.filter(x => x.dir).length > 0) {
                query = query.orderBy(this.state.sort.filter(x => x.dir).map(x => `${x.field} ${x.dir}`).join(', '));
            }

            const predicate = this.getCompositeFilterPredicate(this.state.filter);
            if (predicate) {
                query = query.where(predicate);
            }

            this.entityManager.executeQuery(query)
                .then((response) => {
                    if (this.group) {
                        this.state.group = this.group;
                        const stateForProcess = { ...this.state };
                        stateForProcess.skip = 0;
                        this.internalData = process(response.results, stateForProcess);
                        this.internalData.total = response.inlineCount;
                    } else {
                        this.internalData = {
                            total: response.inlineCount,
                            data: response.results
                        };
                    }
                    this.isBusy = false;
                }).catch(e => {
                console.error(e);
                this.isBusy = false;
                if (e.status === 400) {
                    throw(e);
                }
            });
        } catch (e) {
            console.error(e);
            this.isBusy = false;
        }
    }

    private getCompositeFilterPredicate(filter: CompositeFilterDescriptor): Predicate {
        if (!filter) {
            return null;
        }

        const predicates = filter.filters.map((x) => {
            if ((x as CompositeFilterDescriptor).filters) {
                return this.getCompositeFilterPredicate(x as CompositeFilterDescriptor);
            } else {
                return this.getFilterPredicate(x as FilterDescriptor);
            }
        });

        if (predicates.length === 0) {
            return null;
        } else if (predicates.length === 1) {
            return predicates[0];
        } else {
            const logic = filter.logic === 'and' ? Predicate.and : Predicate.or;

            return logic(predicates);
        }
    }

    private getFilterPredicate({field, operator, value}: FilterDescriptor) {
        switch (operator) {
            case 'isnotnull': // NotNull
                return Predicate.create(field, FilterQueryOp.NotEquals, null);
            case 'isnull': // IsNull
                return Predicate.create(field, FilterQueryOp.Equals, null);
            case 'eq': // Equal
                return Predicate.create(field, FilterQueryOp.Equals, value);
            case 'neq': // NotEqual
                return Predicate.create(field, FilterQueryOp.NotEquals, value);
            case 'lt': // LessThan
                return Predicate.create(field, FilterQueryOp.LessThan, value);
            case 'lte': // LessOrEqual
                return Predicate.create(field, FilterQueryOp.LessThanOrEqual, value);
            case 'gt': // GreaterThan
                return Predicate.create(field, FilterQueryOp.GreaterThan, value);
            case 'gte': // GreaterOrEqual
                return Predicate.create(field, FilterQueryOp.GreaterThanOrEqual, value);
            case 'contains': // Contains
                return Predicate.create(field, FilterQueryOp.Contains, value);
            case 'doesnotcontain': // NotContain
                return Predicate.not(Predicate.create(field, FilterQueryOp.Contains, value));
            case 'startswith': // StartsWith
                return Predicate.create(field, FilterQueryOp.StartsWith, value);
            case 'endswith': // EndsWith
                return Predicate.create(field, FilterQueryOp.EndsWith, value);
            case 'isempty':
                return Predicate.create(field, FilterQueryOp.Equals, '');
            case 'isnotempty':
                return Predicate.create(field, FilterQueryOp.NotEquals, '');

            default:
                throw new Error('Unknown operator for remoteFilter - ' + operator);
        }
    }

    onSelectionChange(event: SelectionEvent) {
        this.selectionChange.emit(event);
    }

    getGrid() {
        return this.grid;
    }

    onDetailExpand(event: DetailExpandEvent) {
        this.detailExpand.emit(event);
    }


    private handleDragAndDrop(): Subscription {
        const sub = new Subscription(() => {
        });
        let dragIndex;
        let dropIndex;
        let dragTr;
        let dropTr;
        const closestTr = (node) => {
            while (node && node.tagName.toLowerCase() !== 'tr') {
                node = node.parentNode;
            }
            return node;
        };

        const tableRows = Array.from(document.querySelectorAll('#' + this.id + ' tbody tr'));
        const tableRows2 = Array.from(document.querySelectorAll('#' + this.id));
        _.each(tableRows2, row => {
            // this.renderer.addClass(row, 'draggable');
        });

        tableRows.forEach(item => {
            this.renderer.setAttribute(item, 'draggable', 'true');
            const dragStart = fromEvent<DragEvent>(item, 'dragstart');
            const dragOver = fromEvent(item, 'dragover');
            const dragEnd = fromEvent(item, 'dragend');

            sub.add(dragStart.pipe(
                tap(({dataTransfer}) => {
                    try {
                        const dragImgEl = document.createElement('span');
                        dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
                        document.body.appendChild(dragImgEl);
                        dataTransfer.setDragImage(dragImgEl, 0, 0);
                    } catch (err) {
                        console.log(err);
                        // IE doesn't support setDragImage
                    }
                    try {
                        // Firefox won't drag without setting data
                        dataTransfer.setData('application/json', '');
                    } catch (err) {
                        console.log(err);
                        // IE doesn't support MIME types in setData
                    }
                })
            ).subscribe(({target}) => {
                dragTr = closestTr(target);
                this.renderer.addClass(dragTr, 'is-dragging');
                dragIndex = dragTr.rowIndex - 1;
            }));

            sub.add(dragOver.subscribe((e: any) => {
                e.preventDefault();
                if (!dragTr) {
                    return;
                }
                dropTr = closestTr(e.target);
                tableRows.forEach(x => { this.renderer.removeClass(x, 'is-dropping'); });

                dropIndex = dropTr.rowIndex - 1;
                if (dropIndex !== dragIndex) {
                    this.renderer.addClass(dropTr, 'is-dropping');
                }
            }));

            sub.add(dragEnd.subscribe((e: any) => {
                e.preventDefault();
                tableRows.forEach(x => { this.renderer.removeClass(x, 'is-dropping'); });
                this.renderer.removeClass(dragTr, 'is-dragging');
                const draggedRow = this.internalData.data[dropIndex];
                const droppedRow = this.internalData.data[dragIndex];
                this.onRowSwap(draggedRow, droppedRow);
                dragTr = null;
                this.applicationRef.tick();
            }));
        });

        return sub;
    }
}

import { CommonModule as NgCommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { AppControlComponent } from './components/app-control/app-control.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardTabsetComponent } from './components/card-tabset/card-tabset.component';
import { CardTabsetMenuComponent } from './components/card-tabset-menu/card-tabset-menu.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/login/logout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AuthGuard } from './guards/auth.guard';
import { AppHttpInterceptor } from './interceptors/http-interceptor';
import { BreezeService } from './services/breeze.service';
import { DialogService } from './services/dialog.service';
import { NavigationService } from './services/navigation.service';
import { PageService } from './services/page.service';
import { TitleService } from './services/title.service';
import { KendoUiMessageService } from './services/kendoui-message.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { PagerPageSizesComponent } from './components/pager/page-sizes.component';
import { PagerNextButtonsComponent } from './components/pager/next-buttons.component';
import { PagerPrevButtonsComponent } from './components/pager/prev-buttons.component';
import { PagerComponent } from './components/pager/pager.component';
import { PagerInfoComponent } from './components/pager/info.component';
import { MultipleInsertModalComponent } from './components/multiple-insert-modal/multiple-insert-modal.component';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { CodelistPipe } from './pipes/codelist.pipe';
import { AppGridComponent } from './components/app-grid/app-grid.component';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { EntitiesErrorsComponent } from './components/entities-errors/entities-errors.component';
import { EntityRevisionsComponent } from './components/entity-revisions/entity-revisions.component';
import { ErrorDialogComponent } from './components/error-dialog.component';
import { UploadSingleFileComponent } from './components/upload-single-file.component';
import { UploadMultipleFilesComponent } from './components/upload-multiple-files.component';
import { UploadActionButtonsComponent } from './components/upload-action-buttons.component';
import { DialogFormComponent } from './components/dialog-form/dialog-form.component';
import { WarningDialogComponent } from '@common/components/warning-dialog.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PadPipe } from './pipes/pad.pipe';
import { AppFormComponent } from './components/app-form/app-form.component';
import { RemoteLogService } from '@common/services/remote-log.service';
import { AccordionHeaderComponent } from './components/accordion-header.component';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { DurationPipe } from './pipes/duration.pipe';
import { UserNotificationModalComponent } from './components/user-notification-modal/user-notification-modal.component';
import { UserNotificationListComponent } from './components/user-notification-list/user-notification-list.component';
import { UserCreateComponent } from './user-create/user-create.component';

@NgModule({
    imports: [
        NgCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

        FontAwesomeModule,

        NgbModule,

        TranslateModule,

        GridModule,
        InputsModule,
        DropDownsModule,
        FloatingLabelModule,
        DateInputsModule,
        DatePickerModule,
        LayoutModule,
        FileSelectModule,

        NgxDnDModule
    ],
    exports: [
        NgCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

        NgbModule,

        FontAwesomeModule,

        GridModule,
        InputsModule,
        DropDownsModule,
        FloatingLabelModule,
        DateInputsModule,
        DatePickerModule,
        LayoutModule,

        PagerInfoComponent,
        PagerPageSizesComponent,
        PagerPrevButtonsComponent,
        PagerNextButtonsComponent,
        PagerComponent,

        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        ActionBarComponent,
        BreadcrumbComponent,
        AppControlComponent,
        AppFormComponent,
        CheckboxComponent,
        LoaderComponent,
        CardTabsetComponent,
        CardTabsetMenuComponent,
        ContentHeaderComponent,
        AccordionHeaderComponent,
        UserNotificationListComponent,

        TranslateModule,
        CodelistPipe,
        PadPipe,
        DurationPipe,

        AppGridComponent,
        EntityRevisionsComponent,
        NgxDnDModule,
        EntityFormComponent,
    ],
    declarations: [
        PagerInfoComponent,
        PagerPageSizesComponent,
        PagerPrevButtonsComponent,
        PagerNextButtonsComponent,
        PagerComponent,
        UserCreateComponent,
        LoginComponent,
        LogoutComponent,
        UnauthorizedComponent,
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        BreadcrumbComponent,
        AppControlComponent,
        CheckboxComponent,
        LoaderComponent,
        CardTabsetComponent,
        CardTabsetMenuComponent,
        ConfirmDialogComponent,
        ChangesDialogComponent,
        WarningDialogComponent,
        ActionBarComponent,
        ContentHeaderComponent,
        AccordionHeaderComponent,
        MultipleInsertModalComponent,
        CodelistPipe,
        AppGridComponent,
        EntitiesErrorsComponent,
        EntityRevisionsComponent,
        DialogFormComponent,
        ErrorDialogComponent,
        UploadSingleFileComponent,
        UploadMultipleFilesComponent,
        UploadActionButtonsComponent,
        UnauthorizedComponent,
        PadPipe,
        AppFormComponent,
        EntityFormComponent,
        DurationPipe,
        UserNotificationModalComponent,
        UserNotificationListComponent
    ],
    entryComponents: [
        ConfirmDialogComponent,
        WarningDialogComponent,
        ChangesDialogComponent,
        EntitiesErrorsComponent,
        DialogFormComponent,
        ErrorDialogComponent,
        UserNotificationModalComponent
    ],
    providers: [
        TitleService,
        NavigationService,
        DialogService,
        PageService,
        KendoUiMessageService,
        {
            provide: MessageService,
            useClass: KendoUiMessageService
        },
        AuthGuard,
        BreezeService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        RemoteLogService
    ]
})
export class CommonModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIconPacks(far);
        library.addIconPacks(fab);
    }
}

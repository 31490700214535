import { Component, OnInit, Input, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, ComponentFactoryResolver, Injector, ViewChild } from '@angular/core';
import { EntityManager } from '@cime/breeze-client';
import { BreezeEntity } from '@common/classes/breeze-entity';
import * as _ from 'lodash';
import { AppGridComponent } from '../app-grid/app-grid.component';
import { SelectionEvent, ColumnComponent } from '@progress/kendo-angular-grid';

export interface EntityFormOptions {
    entityName: string;
    beforeAdd?: (data: any) => void;
    propertyGroups: EntityProperty[][];
    onAdd?: (entity: BreezeEntity) => void;
    onRemove?: (entity: BreezeEntity) => void;
}

export interface EntityProperty {
    name: string;
    label?: string;
    colSize?: number;
    type?: string;
    options?: any[];
    textField?: string;
    valueField?: string;
    valuePrimitive?: boolean;
    time?: boolean;
    isVisible?: (model: any) => boolean;
    isDisplayed?: (model: any) => boolean;
    isDisabled?: (model: any) => boolean;
    format?: string;
}

export const EmptyEntityProperty: EntityProperty = {
    name: null,
    label: null
};

@Component({
    selector: 'app-entity-form',
    templateUrl: './entity-form.component.html',
    styleUrls: ['./entity-form.component.css']
})
export class EntityFormComponent implements OnInit, AfterViewInit, OnDestroy {

    private localEntityManager: EntityManager;
    private propertyNames: string[];
    private entityName: string;
    private edit = false;

    constructor() { }

    model: BreezeEntity;

    @Input() options: EntityFormOptions;
    @Input() grid: AppGridComponent;
    @ViewChild('removeColumn', { static: false }) removeColumn;

    add() {
        this.model.entityAspect.validateEntity();
        if (_.filter(this.model.entityAspect.getValidationErrors(),
                o => this.propertyNames.indexOf(o.propertyName) >= 0).length > 0) {
            return;
        }

        const data = this.getData(this.model);
        this.options.beforeAdd?.(data);

        const entity = this.grid.entityManager.createEntity(this.entityName, data);
        (<any[]>this.grid.data).push(entity);
        this.options.onAdd?.(entity);
        this.clear();
    }

    clear() {
        // Detach the entity and create a new one instead of setting the values to null,
        // in order to prevent validation errors from showing
        if (!this.edit) { // Do not detach when entity belongs to another entity manager
            this.localEntityManager.detachEntity(this.model);
        }

        this.grid.selection.length = 0;
        this.setupModel();
        this.edit = false;
    }

    remove(entity: BreezeEntity) {
        entity.entityAspect.setDeleted();
        this.options.onRemove?.(entity);
    }

    ngOnInit(): void {
        this.entityName = this.options.entityName;
        this.propertyNames = _.chain(this.options.propertyGroups).flatten().map(o => o.name).value();
        this.localEntityManager = this.grid.entityManager.createEmptyCopy();
        this.localEntityManager.setProperties({
            validationOptions: this.localEntityManager.validationOptions.using({ validateOnAttach: false })
        });
        this.grid.selectable = { mode: 'single' };
        this.grid.selectionChange.subscribe((event: SelectionEvent) => {
            if (!event.selectedRows.length) {
                this.clear();
                return;
            }

            this.edit = true;
            this.model = event.selectedRows[0].dataItem;
        });

        this.setupModel();
    }

    ngAfterViewInit(): void {
        this.grid.addColumn(this.removeColumn);
    }

    ngOnDestroy(): void {
    }

    getName(property: EntityProperty) {
        return property.name;
    }

    getDisplayedProperties(propertyGroup: EntityProperty[]) {
        return _.filter(propertyGroup, g => this.isDisplayed(g));
    }

    isDisabled(property: EntityProperty) {
        return property.isDisabled?.(this.model) === true;
    }

    isVisible(property: EntityProperty) {
        return property.name && property.isVisible?.(this.model) !== false;
    }

    isDisplayed(property: EntityProperty) {
        return property.isDisplayed?.(this.model) !== false;
    }

    private setupModel() {
        this.model = this.localEntityManager.createEntity(this.entityName, this.getData(null));
    }

    private getData(entity) {
        const data = {};
        _.each(this.propertyNames, propName => {
            data[propName] = !entity ? null : entity[propName];
        });

        return data;
    }
}

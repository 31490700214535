import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <div class="alert alert-danger" role="alert">
                <div [innerHTML]="message"></div>
            </div>

            <div *ngIf="hasStackTrace()">
                <div class="panel panel-danger">
                    <div class="panel-heading" translate>StackTrace</div>
                    <div class="panel-body">
                        <pre style="color: #c7254e; max-height: 500px; overflow-y: scroll;" [innerHTML]="stackTrace"></pre>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="activeModal.close(true)" translate>Ok</button>
        </div>
    `,
})
export class ErrorDialogComponent {
    stackTrace: string;
    message: string;
    isServerSideError: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private translateService: TranslateService) {
    }

    get title() {
        return this.isServerSideError
            ? this.translateService.instant('Server side error occurred')
            : this.translateService.instant('Client side error occurred');
    }

    hasStackTrace() {
        return !!this.stackTrace;
    }

    setMessage(message) {
        if (!message || !_.isString(message)) {
            console.error(message);
            message = this.translateService.instant('Check the console for error details');
        }

        const lines = message.indexOf('\r\n') < 0 ? message.split('\n') : message.split('\r\n');
        let html = '<span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>';
        html += `<span> ${lines[0]}</span>`;

        // Client side error message contains also the stacktrace which we do not want to display
        if (lines.length === 1 || !this.isServerSideError) {
            this.message = html;
            return;
        }

        html += '<br />';
        for (let i = 1; i < lines.length; i++) {
            html += `<span>${lines[i]}</span><br />`;
        }

        this.message = html;
    }

    setStackTrace(stackTrace) {
        if (stackTrace && !_.isString(stackTrace)) {
            console.error(stackTrace);
            stackTrace = stackTrace.toString();
        }

        this.stackTrace = stackTrace;
    }
}

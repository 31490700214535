import { ControlSize } from '@common/classes/control-size';
import { ViewMode } from '@common/models/view-mode';

const hostname = window.location.hostname;
const port = hostname === 'localhost' ? ':5000' : '/be';
const serverUrl = `${window.location.protocol}//${window.location.hostname}${port}`;
export const environment = {
    production: false,
    serverUrl,
    apiUrl: `${serverUrl}/api`,
    graphqlUrl: `${serverUrl}/graphql`,
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    title: 'PCS Ploce',
    version: '0.1.0',

    settings: {
        appControl: {
            format: '#',
            size: ControlSize.normal,
            multi: false,
            time: false,
            codelist: {
                take: 100
            },
            fileDownloadUrl: 'api:///query/DownloadAttachment',
            fileMaxSize: 5242880, // 5MB
            fileAllowedExtensions: [
                '.jpg', '.jpeg', '.png', '.bmp', '.tif', // Images
                '.doc', '.docx', '.xls', '.xlsx', '.txt', '.pdf' // Documents
            ]
        },
        grid: {
            pageable: { type: 'numeric', info: true, buttonCount: 4, pageSizes: [10, 20, 50] },
            pageSize: 10,
            pageSizes: [10, 20, 50],
            sortable: false,
            sort: []
        },
        view: {
            defaultViewMode: ViewMode.view,
            save: {
                redirectToViewMode: ViewMode.view
            }
        },
        list: {
            persistFilter: true
        },
        validation: {
            errors: {
                showTooltip: true,
                showFeedback: false
            }
        }
    }
};

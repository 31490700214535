import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { EntityManager } from '@cime/breeze-client';
import { UserService } from '../services/user.service';
import { ToastyService } from '@cime/ngx-toasty';
import { User } from '@common/breeze-models/user';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    private fetchMetadataPromise: Promise<boolean>;

    constructor(
        private entityManager: EntityManager,
        private userService: UserService,
        private toastyService: ToastyService,
        private translateService: TranslateService,
        private router: Router) {

        userService.isAuthenticatedSubject.pipe(filter(x => !x)).subscribe(value => {
            this.fetchMetadataPromise = null;
        });
    }

    private async fetchUserAndMetadata(state: RouterStateSnapshot) {
        try {
            await this.userService.getCurrentUser();
            await this.userService.getCountUser();

            if (this.entityManager.metadataStore.isEmpty()) {
                await this.entityManager.fetchMetadata().then(store => {
                    // TODO: move elsewhere
                    this.entityManager.metadataStore.registerEntityTypeCtor('User', User);
                });
            }

            return true;
        } catch (error) {
            this.toastyService.error(this.translateService.instant('You must be authenticated!'));
            this.userService.logout().then(() => {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            });

            return false;
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.fetchMetadataPromise) {
            this.fetchMetadataPromise = this.fetchUserAndMetadata(state);
        }

        return this.fetchMetadataPromise;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }
}


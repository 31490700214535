import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivationStart } from '@angular/router';
import { UserService } from './common/services/user.service';
import { TitleService } from './common/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { SplitterComponent } from '@progress/kendo-angular-layout';
import { filter } from 'rxjs/operators';
import { ToastyService } from '@cime/ngx-toasty';
import { User } from '@common/models/User';
import * as _ from 'lodash';
import { DialogService } from '@common/services/dialog.service';
import { RemoteLogService } from '@common/services/remote-log.service';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger(
            'slideInOut', [
            transition(':enter', [
                style({transform: 'translateX(100%)', opacity: 0}),
                animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
            ]), transition(':leave', [
                style({transform: 'translateX(0)', opacity: 1}),
                animate('500ms', style({transform: 'translateX(100%)', opacity: 0})) ])
            ])
    ]
})
export class AppComponent implements AfterViewInit {
    isAuthenticated = false;
    currentUser: User;
    visible = true;
    @ViewChild('consoleSplitter', { static: false }) consoleSplitter: SplitterComponent;

    constructor(
        private router: Router,
        private userService: UserService,
        private titleService: TitleService,
        private toastyService: ToastyService,
        private translate: TranslateService,
        private dialogService: DialogService,
        public consoleWindowService: ConsoleWindowService,
        private remoteLogService: RemoteLogService) {

        remoteLogService.connect().then(() => {
            remoteLogService.info('Client connected');
        });

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const title = this.getDeepestTitle(this.router.routerState.snapshot.root);
                titleService.setTitle(title);
            }
        });

        router.events.pipe(filter(event =>
            event instanceof ActivationStart &&
            event.snapshot.data.permissions &&
            (!_.isArray(event.snapshot.data.permissions) || event.snapshot.data.permissions.length > 0))
        ).subscribe((event) => {
            const permissions = (event as ActivationStart).snapshot.data.permissions;

            if (!this.currentUser.hasPermission(permissions)) {
                console.dir(event);
                console.error('Authorization failed.', this.currentUser, permissions);
                this.toastyService.error(this.translate.instant('Unauthorized'));

                this.router.navigate(['/401'], { queryParams: { returnUrl: this.getResolvedUrl((event as ActivationStart).snapshot) } });
            }
        });

        userService.isAuthenticatedSubject.subscribe((value) => {
            this.isAuthenticated = value;
        });

        userService.currentUserSubject.subscribe((value) => {
            this.currentUser = value;

            if (this.currentUser) {
                try {
                    const lang = this.currentUser.language.id.toLowerCase();
                    this.translate.use(lang);
                } catch {

                }
            }
        });
    }

    private getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title = routeSnapshot.data ? routeSnapshot.data['title'] : '';

        if (routeSnapshot.firstChild) {
            title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
        }

        return title;
    }

    ngAfterViewInit() {
        this.consoleWindowService.initialize(this.consoleSplitter);
    }
}

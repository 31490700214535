import * as _ from 'lodash';
import { Validator, ValidationOptions, NavigationProperty } from '@cime/breeze-client';

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const gettext = (value) => value;

export class FluentValidators {
    registerAsBreezeValidators() {
        Validator.registerFactory(this.notNullValidator(), 'fvNotNull');
        Validator.registerFactory(this.notEmptyValidator(), 'fvNotEmpty');
        Validator.registerFactory(this.emailValidator(), 'fvEmail');
        Validator.registerFactory(this.regularExpressionValidator(), 'fvRegularExpression');
        Validator.registerFactory(this.creditCardValidator(), 'fvCreditCard');
        Validator.registerFactory(this.lengthValidator(), 'fvLength');
        Validator.registerFactory(this.exactLengthValidator(), 'fvExactLength');
        Validator.registerFactory(this.exclusiveBetweenValidator(), 'fvExclusiveBetween');
        Validator.registerFactory(this.inclusiveBetweenValidator(), 'fvInclusiveBetween');
        Validator.registerFactory(this.equalValidator(), 'fvEqual');
        Validator.registerFactory(this.notEqualValidator(), 'fvNotEqual');
        Validator.registerFactory(this.lessThanValidator(), 'fvLessThan');
        Validator.registerFactory(this.lessThanOrEqualValidator(), 'fvLessThanOrEqual');
        Validator.registerFactory(this.greaterThanValidator(), 'fvGreaterThan');
        Validator.registerFactory(this.greaterThanOrEqualValidator(), 'fvGreaterThanOrEqual');
        Validator.registerFactory((options?: ValidationOptions) => this.scalePrecisionValidator(options), 'fvScalePrecision');
    }

    scalePrecisionValidator(ctx?: any) {
        // 5,3  == #####,###
        const scale = parseInt(ctx.scale, 10);
        const precision = parseInt(ctx.precision, 10);
        const format = _.repeat('#', precision) + '.' + _.repeat('#', scale);

        return new Validator(
            'decimal', // Validator name.
            this.decimalValidationFn,
            {
                messageTemplate: `This value is not a valid number: ${format}`,
                precision: ctx.precision,
                scale: ctx.scale
            });
    }

    private decimalValidationFn(value: any, context: any): boolean {
        if (value == null) {
            return true;
        }

        const precision = context.precision;
        const scale = context.scale;

        const pattern = `^\\d{0,${precision}}(\\.\\d{0,${scale}})?$`;
        const regExp = new RegExp(pattern);

        return regExp.test(value);
    }

    notNullValidator(): (ctx?: any) => Validator {
        return (context?) => {
            const requireVal: any = Validator.required(null);
            const valFn = (valueOf, ctx) => {
                ctx.allowEmptyStrings = true;
                return requireVal.valFn(valueOf, ctx);
            };
            return this.decorateValidator(new Validator('fvNotNull', valFn, context));
        };
    }

    notEmptyValidator(): (ctx?: any) => Validator {
        return (context?) => {
            const valFn = (valueOf, ctx) => {
                if (ctx.property instanceof NavigationProperty) {
                    if (!ctx.entity ||
                        !ctx.entity.entityAspect ||
                        !ctx.entity.entityAspect.isNavigationPropertyLoaded(ctx.property)) {
                        return true;
                    }
                    if (!ctx.property.isScalar) {

                        return valueOf != null && valueOf.length > 0;
                    } else {
                        return valueOf != null;
                    }
                }

                if (typeof valueOf === 'string') {
                    return valueOf.length > 0;
                } else if (typeof valueOf === 'number') {
                    return valueOf !== 0;
                } else if (Array.isArray(valueOf)) {
                    // if array check only if the navigation property is loaded
                    if (ctx.entity &&
                        ctx.entity.entityAspect &&
                        ctx.entity.entityAspect.isNavigationPropertyLoaded(ctx.property)) {
                        return valueOf.length > 0;
                    }
                    return true;
                } else {
                    return valueOf != null;
                }
            };
            return this.decorateValidator(new Validator('fvNotEmpty', valFn, context));
        };
    }

    emailValidator(): (ctx?: any) => Validator {
        return (context?) => {
            const val: any = Validator.emailAddress(context);
            return this.decorateValidator(new Validator('fvEmail', val.valFn, context));
        };
    }

    regularExpressionValidator(): (ctx?: any) => Validator {
        return (context?) => {
            const val: any = (Validator as any).regularExpression();
            return this.decorateValidator(new Validator('fvRegularExpression', val.valFn, context));
        };
    }

    creditCardValidator(): (ctx?: any) => Validator {
        return (context?) => {
            const val: any = Validator.creditCard(context);
            return this.decorateValidator(new Validator('fvCreditCard', val.valFn, context));
        };
    }

    exactLengthValidator(): (ctx?: any) => Validator {
        return this.lengthValidator('fvExactLength');
    }

    lengthValidator(valName?: string): (ctx?: any) => Validator {
        valName = valName || 'fvLength';
        const stringLengthVal = (Validator as any).stringLength();
        const validator = (context?) => {
            const valFn = (valueOf, ctx) => {
                ctx.minLength = ctx.min;
                ctx.maxLength = ctx.max;
                return stringLengthVal.valFn(valueOf, ctx);
            };
            return this.decorateValidator(new Validator(valName, valFn, context));
        };
        return validator;
    }

    exclusiveBetweenValidator(): (ctx?: any) => Validator {
        return (context?) => {
            const valFn = (valueOf, ctx) => {
                return valueOf > ctx.from && valueOf < ctx.to;
            };
            return this.decorateValidator(new Validator('fvExclusiveBetween', valFn, context));
        };
    }

    inclusiveBetweenValidator(): (ctx?: any) => Validator {
        return (context?) => {
            const valFn = (valueOf, ctx) => {
                return valueOf >= ctx.from && valueOf <= ctx.to;
            };
            return this.decorateValidator(new Validator('fvInclusiveBetween', valFn, context));
        };
    }

    equalValidator(): (ctx?: any) => Validator {
        return this.comparisonValidator('Equal', 'fvEqual');
    }

    notEqualValidator(): (ctx?: any) => Validator {
        return this.comparisonValidator('NotEqual', 'fvNotEqual');
    }

    lessThanValidator(): (ctx?: any) => Validator {
        return this.comparisonValidator('LessThan', 'fvLessThan');
    }

    lessThanOrEqualValidator(): (ctx?: any) => Validator {
        return this.comparisonValidator('LessThanOrEqual', 'fvLessThanOrEqual');
    }

    greaterThanValidator(): (ctx?: any) => Validator {
        return this.comparisonValidator('GreaterThan', 'fvGreaterThan');
    }

    greaterThanOrEqualValidator(): (ctx?: any) => Validator {
        return this.comparisonValidator('GreaterThanOrEqual', 'fvGreaterThanOrEqual');
    }

    private decorateValidator(validator: Validator): Validator {
        validator.context = validator.context || {};
        (validator.context as any).message = (ctx) => {
            const expr = _.template(gettext(ctx.errorMessageId));

            return expr(ctx);
        };
        return validator;
    }

    private comparisonValidator(comparison: string, valName: string) {
        return (context?) => {
            const valFn = (valueOf, ctx) => {
                const camelCaseConv = ctx.entity.entityAspect.entityManager.metadataStore.namingConvention.name === 'camelCase';
                const valToCompare = !!ctx.memberToCompare
                    ? ctx.entity.getProperty((camelCaseConv ? _.camelCase(ctx.memberToCompare) : ctx.memberToCompare)) // TODO: better way
                    : ctx.valueToCompare;

                ctx['comparisonValue'] = valToCompare;

                // var comparison = ctx.comparison;
                switch (comparison) {
                    case 'Equal':
                        return valueOf === valToCompare;
                    case 'NotEqual':
                        return valueOf !== valToCompare;
                    case 'LessThan':
                        return valueOf < valToCompare;
                    case 'LessThanOrEqual':
                        return valueOf <= valToCompare;
                    case 'GreaterThan':
                        return valueOf > valToCompare;
                    case 'GreaterThanOrEqual':
                        return valueOf >= valToCompare;
                    default:
                        throw new Error(`Unknown comparison: '${comparison}'`);
                }
            };
            return this.decorateValidator(new Validator(valName, valFn, context));
        };
    }
}

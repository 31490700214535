<app-content-header *ngIf="showHeader" title="{{'Notifications'|translate}}" [breadcrumb]="breadcrumb"></app-content-header>
<div *ngIf="notifications.length">
    <div class="card mt-3" *ngFor="let notification of notifications" [ngClass]="{ 'text-white bg-danger': notification.important }">
        <div class="card-body">
            <h1 class="card-title">{{notification.title}}</h1>
            <h6 class="card-subtitle mb-3">
                <span translate>Created</span>
                <span>: </span>
                <span>{{notification.createdDate | date:'short'}}</span>
                <span> by </span>
                <span>{{notification.createdById | codelist:notification:'createdById'|async}}</span>
            </h6>
            <p class="card-text" [innerHtml]="notification.content"></p>
            <a href="#" (click)="download($event, attachment.attachment.id)" *ngFor="let attachment of notification.attachments" class="card-link">{{attachment.attachment.name}}</a>
        </div>
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { isObservable, Observable } from 'rxjs';
import * as _ from 'lodash';

type PropertyFunction<T> = () => T;

export interface IActionBarItem {
    label?: string | PropertyFunction<string>;
    icon?: string | PropertyFunction<string>;
    isDisabled?: boolean | PropertyFunction<boolean>;
    isVisible?: boolean | PropertyFunction<boolean>;
    onClick?: () => Promise<any> | Observable<any> | void;
    smallItems?: IActionBarItem[];
    options?: IActionBarItem[];
    style?: string | PropertyFunction<string>;
}

export interface IActionBarGroup {
    label: string;
    items: IActionBarItem[];
    isDisabled?: boolean | PropertyFunction<boolean>;
    isVisible?: boolean | PropertyFunction<boolean>;
}

/**
 * A component that makes it easy to create actionbar (toolbar)
 */
@Component({
    selector: 'app-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit {

    @Input() items: IActionBarGroup[];
    @Input() collapsed = false;
    @Input() collapsible = false;
    @Input() tooltipPlacement: PlacementArray = 'bottom';
    @Input() tooltipOpenDelay = 1000;

    ngOnInit() {

    }

    async onClick(item: IActionBarItem, group: IActionBarGroup) {
        if (item.onClick && !this.isDisabled(item, group)) {
            let result = item.onClick();

            if (result) {
                try {
                    (item as any)._isBusy = true;

                    if (isObservable(result)) {
                        result = result.toPromise();
                    }

                    await result;
                } catch (e) {

                }

                (item as any)._isBusy = false;
            }
        }
    }

    label(item: IActionBarItem) {
        if (_.isFunction(item.label)) {
            return item.label();
        }

        return item.label;
    }

    icon(item: IActionBarItem) {
        if (_.isFunction(item.icon)) {
            return item.icon();
        }

        return item.icon;
    }

    isDisabled(item: IActionBarItem, group: IActionBarGroup) {
        if (
            _.isBoolean(group.isDisabled) && group.isDisabled ||
            _.isFunction(group.isDisabled) && group.isDisabled() ||
            _.isBoolean(item.isDisabled) && item.isDisabled ||
            _.isFunction(item.isDisabled) && item.isDisabled()
        ) {
            return true;
        }

        return (item as any)._isBusy === true;
    }

    isVisible(item: IActionBarItem | IActionBarGroup) {
        if (_.isFunction(item.isVisible) && !item.isVisible()) {
            return false;
        }

        if (_.isBoolean(item.isVisible) && !item.isVisible) {
            return false;
        }

        if ((<IActionBarGroup>item).items) {
            return _.some((<IActionBarGroup>item).items, (x) => this.isVisible(x));
        }

        return true;
    }


    toggleActionBarVisibility() {
        this.collapsed = !this.collapsed;
    }

    hasAnyVisibleGroup() {
        return this.items && _.some(this.items, (x) => this.isVisible(x));
    }
}

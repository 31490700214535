import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
      if (!value) {
          return;
      }

      return moment.duration(value * 60 * 60 * 1000).humanize();
  }

}

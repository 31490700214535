<kendo-grid #grid
        id="{{id}}" 
            [data]="internalData"
            [loading]="isBusy"
            [skip]="state.skip"
            [pageable]="pageable"
            [pageSize]="state.take"
            [sortable]="sortable"
            [sort]="state.sort"
            [scrollable]="scrollable"
            [filterable]="filterable"
            [filter]="state.filter"
            (dataStateChange)="onDataStateChanged($event)"
            (selectionChange)="onSelectionChange($event)"
            [selectable]="selectable"
            [kendoGridSelectBy]="selectBy"
            (detailExpand)="onDetailExpand($event)"
            [selectedKeys]="selection"
            [groupable]="groupable"
            [height]="height"
            [columnMenu]="false"
            [reorderable]="true">

</kendo-grid>

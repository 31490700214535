<div class="aaa">
    <fa-icon class="ml-1" [icon]="['fas', 'exclamation']"></fa-icon> 
    <fa-icon class="ml-1" [icon]="['fas', 'exclamation']"></fa-icon> 
    <fa-icon class="ml-1" [icon]="['fas', 'exclamation']"></fa-icon> 
    {{'List of errors' | translate}}
</div>
<app-grid class="" [data]="errors" >
    <kendo-grid-column title="{{'Entity'|translate}}" [headerStyle]="{'background-color': '#F54029'}">
        <ng-template kendoGridCellTemplate let-item>
            <span>{{item.entity.entityType.shortName | translate}}</span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{'Property'|translate}}" field="propertyName"  [headerStyle]="{'background-color': '#F54029'}">
        <ng-template kendoGridCellTemplate let-item>
            <span>{{item.propertyName | translate}}</span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{'Error'|translate}}" field="errorMessage" [headerStyle]="{'background-color': '#F54029'}" >
    </kendo-grid-column>
</app-grid>

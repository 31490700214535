// tslint:disable-next-line:max-line-length
import { HttpEvent, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { SlimLoadingBarService } from '@cime/ngx-slim-loading-bar';
import { Observable, throwError, BehaviorSubject, NEVER } from 'rxjs';
import { tap, catchError, switchMap, filter, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserService } from '@common/services/user.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    private activeRequests = 0;
    private refreshingToken = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private slimLoadingBarService: SlimLoadingBarService,
        private userService: UserService,
        private router: Router,
        private errorHandler: ErrorHandler) {
    }

    // tslint:disable-next-line:max-line-length
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const refreshToken = this.userService.getRefreshToken();
        req = this.setTokenAndUrl(req, this.userService.getAccessToken());

        this.activeRequests++;
        this.slimLoadingBarService.start();

        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.Response) {
                    this.decreaseActiveRequests();
                }
            }, (error) => {
                this.decreaseActiveRequests();

                return throwError(error);
            }),
            catchError(error => {
                if (this.shouldRefreshToken(error)) {
                    return this.refreshToken(req, next, refreshToken);
                } else if (error.status >= 500) {
                    this.errorHandler.handleError(error);
                }

                return throwError(error);
            }));
    }

    private shouldRefreshToken(error: any) {
        return error instanceof HttpErrorResponse &&
            !_.endsWith(error.url, 'RefreshAccessToken') &&
            !_.endsWith(error.url, 'Login') &&
            error.headers.has('Token-Expired') &&
            error.status === 401;
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler, refreshToken) {
        if (!this.refreshingToken && refreshToken === this.userService.getRefreshToken()) {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);

            return this.userService.refreshAccessToken().pipe(
                switchMap((token: any) => {
                    this.refreshingToken = false;
                    this.refreshTokenSubject.next(token.accessToken);
                    return next.handle(this.setTokenAndUrl(request, token.accessToken));
                }),
                catchError(error => {
                    this.refreshingToken = false;
                    // logout only when the refresh token is invalid
                    if (error.status === 400) {
                        this.userService.logout().then(() => {
                            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                        });
                        return NEVER;
                    }

                    return throwError(error);
                }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.setTokenAndUrl(request, token));
                }));
        }
    }

    private setTokenAndUrl(request: HttpRequest<any>, token: string) {
        const headers: any = {};
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        return request.clone({
            setHeaders: headers,
            url: request.url && request.url.indexOf('api://') === 0
                    ? request.url.replace('api://', environment.apiUrl)
                : request.url
        });
    }

    private decreaseActiveRequests() {
        this.activeRequests--;

        if (this.activeRequests === 0) {
            this.slimLoadingBarService.complete();
        }
    }
}

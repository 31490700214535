import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { User } from '../../models/User';
import { MenuItem, NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';
import { filter, first } from 'rxjs/operators';
import { SharedService } from '../../services/shared.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    url: string;
    navigation: MenuItem[];
    isCollapsed = false;
    selectedItem: MenuItem;
    selectedItemC: MenuItem;
    isCollapsedChild = true;

    user: User;

    public data = this.isCollapsed;

    constructor(
        private navigationService: NavigationService,
        private router: Router,
        private sharedService: SharedService,
        private userService: UserService
    ) {
        this.sharedService.sendData(this.data);
        this.navigation = navigationService.getNavigation();
        this.selectedItem = this.navigation[0];

        userService.currentUserSubject.subscribe((user) => {
            this.user = user;
            this.setPermissions();
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = this.router.url;
            }
        });

        this.router.events.pipe(filter(x => x instanceof NavigationEnd), first()).subscribe((event) => {
            const item = _.find(this.navigation.filter(x => x.route === this.router.url || _.find(x.children, c => this.router.url.indexOf(c.route) === 0)));

            if (item) {
                this.selectedItem = item;
            }
        });
    }

    isActive(route: string) {
        return this.url && (this.url === route || this.url.indexOf(route) === 0);
    }


    isChildren(child: MenuItem) {
        return child?.children.length === 0;
    }

    selectItem(item) {

        if (this.selectedItem === item) {
            this.selectedItem = null;
            return;
        }
        this.isCollapsed = false;
        this.selectedItem = item;
    }

    selectItemChild(item) {
        this.isCollapsedChild = !this.isCollapsedChild;
        this.selectedItemC = item;
    }

    toggle() {
        this.isCollapsed = !this.isCollapsed;
        this.sharedService.sendData(this.isCollapsed);
    }

    private setPermissions() {
        _.forEach(this.navigation, x => {
            this.setMenuItemPermission(x);
        });
    }

    private setMenuItemPermission(menuItem: MenuItem) {
        menuItem.hasPermission = this.hasPermission(menuItem);
        if (this.user && _.isFunction(menuItem.icon)) {
            menuItem.icon = menuItem.icon(this.user);
        }
        if (this.user && _.isFunction(menuItem.class)) {
            menuItem.class = menuItem.class(this.user);
        }

        _.forEach(menuItem.children, y => {
            this.setMenuItemPermission(y);
        });
    }

    hasPermission(menuItem: MenuItem) {
        if (!this.user) {
            return false;
        }

        const permissions = _.isArray(menuItem.permissions) ? menuItem.permissions : (menuItem.permissions ? [menuItem.permissions] : []);

        if (!menuItem.permissions || (_.isArray(menuItem.permissions) && menuItem.permissions.length === 0)) {
            return true;
        }

        return this.user.hasPermission(...permissions);
    }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { User } from '../../models/User';
import { DialogService } from '../../services/dialog.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Output() toggleSidebar = new EventEmitter();
    user: User;
    userCount: number;
    isAdmin: boolean;

    constructor(
        private router: Router,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        userService.currentUserSubject.subscribe((user) => {
            this.user = user;
        });

        userService.countUserSubject.subscribe((count) => {
            this.userCount = count;
        });
    }

    onToggleSidebar() {
        this.toggleSidebar.emit();
    }

    login() {
        return this.router.navigate(['/login']);
    }

    isAdm() {
        return this.user.isSystemUser || _.find(this.user.organization.roles, (org: any) => org.name === 'ADMIN');
    }

    logout() {
        this.dialogService.confirm(this.translateService.instant('Sign Out') as string, this.translateService.instant('Are you sure you want to sign out?') as string)
            .then((result) => {
                if (result) {
                    this.router.navigate(['/logout']);
                }
            });
    }

    selectLanguage(language) {
        // TODO save users language if logged in?
        this.translateService.use(language);
    }
}

import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from '@common/components/error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { RemoteLogService } from '@common/services/remote-log.service';

@Injectable({
    providedIn: 'root'
})
export class DialogErrorHandler implements ErrorHandler {

    private modalService: NgbModal;

    constructor(private injector: Injector) {

    }

    handleError(error) {
        const remoteLogService = this.injector.get(RemoteLogService);
        remoteLogService.error(error.toString())
            .catch((err) => {
                console.error(err);
            });

        if (!this.modalService) {
            this.modalService = this.injector.get(NgbModal); // injecting modalService in constructor does not work
        }

        if (error.rejection && error.rejection.httpResponse) { // breeze query error
            error = error.rejection.httpResponse.response;
        }

        if (error instanceof HttpErrorResponse) {
            this.handleServerSideError(error);
        } else {
            this.handleClientSideError(error);
        }
    }

    private handleServerSideError(response: HttpErrorResponse) {
        let error = response.error || {};
        if (_.isString(error) && error[0] === '{') { // breeze command with status 500
            try {
                error = JSON.parse(error);
            } catch { }
        }

        this.openErrorDialog(error.errorMessage, error.exception, true);
    }

    private handleClientSideError(error: Error) {
        console.error(error);
        this.openErrorDialog(error.message, error.stack, false);
    }

    private openErrorDialog(errorMessage, stackTrace, serverSide) {
        const dialogRef = this.modalService.open(ErrorDialogComponent, {
            size: 'lg'
        });
        dialogRef.componentInstance.isServerSideError = serverSide;
        dialogRef.componentInstance.setStackTrace(stackTrace);
        dialogRef.componentInstance.setMessage(errorMessage);
    }
}

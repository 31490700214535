import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-upload-multiple-files',
    template: `
    <app-grid class="mb-2" [data]="files">
        <kendo-grid-column title="{{'Name'|translate}}">
            <ng-template let-row kendoGridCellTemplate>
                <span>{{ row.attachment?.name || row.name }}</span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="" [width]="'100px'">
            <ng-template let-row kendoGridCellTemplate>
                <app-upload-action-buttons
                    (removeFile)="onRemoveFile($event)"
                    [file]="getFile(row)"
                    [disabled]="disabled"
                    [multiple]="true">
                </app-upload-action-buttons>
            </ng-template>
        </kendo-grid-column>
    </app-grid>
    `
})
export class UploadMultipleFilesComponent {
    @Input() files: any[];
    @Input() disabled: boolean;

    @Output() public removeFile: EventEmitter<any> = new EventEmitter<any>();

    onRemoveFile(file) {
        this.removeFile.emit(file);
    }

    getFile(row) {
        return row.attachment || row;
    }
}

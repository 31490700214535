import { EntityManager } from '@cime/breeze-client';
import { VesselAnnouncement } from '@vessel-announcement/models/vesselAnnouncement';
import { ServiceRequest } from '@service-request/models/serviceRequest';
import { TruckVisit } from '../../truck-visit/models/truckVisit';
import { Vessel } from '@administration/vessel/models/vessel';
import { CashReceipt } from '../../vehicle-permit/models/CashReceipt';
import { VehiclePermit } from '../../vehicle-permit/models/vehiclePermit';
import { WarehouseDocument } from '@warehouse-document/models/warehouseDocument';
import { WarehouseDocumentRow } from '@warehouse-document/models/warehouseDocumentRow';
import { ServiceRequestRow } from '@service-request/models/serviceRequestRow';

export class CustomConstructors {
    static registerCustomConstructors(entityManager: EntityManager) {
        entityManager.metadataStore.registerEntityTypeCtor('VesselAnnouncement', VesselAnnouncement);
        entityManager.metadataStore.registerEntityTypeCtor('ServiceRequest', ServiceRequest);
        entityManager.metadataStore.registerEntityTypeCtor('ServiceRequestRow', ServiceRequestRow);
        entityManager.metadataStore.registerEntityTypeCtor('TruckVisit', TruckVisit);
        entityManager.metadataStore.registerEntityTypeCtor('Vessel', Vessel);
        entityManager.metadataStore.registerEntityTypeCtor('CashReceipt', CashReceipt);
        entityManager.metadataStore.registerEntityTypeCtor('VehiclePermit', VehiclePermit);
        entityManager.metadataStore.registerEntityTypeCtor('WarehouseDocument', WarehouseDocument);
        entityManager.metadataStore.registerEntityTypeCtor('WarehouseDocumentRow', WarehouseDocumentRow);
    }
}


// tslint:disable: no-access-missing-member
import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';

import { LocalizationService } from '@progress/kendo-angular-l10n';
import { PagerContextService, PagerElementComponent } from '@progress/kendo-angular-grid';
import { PagerContextChanges } from '@progress/kendo-angular-grid/dist/es2015/pager/pager-context.service';

/**
 * Displays buttons for navigating to the next and to the last page ([see example]({% slug paging_grid %}#toc-pager-templates)).
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-pager-next-buttons',
    template: `
        <a  href="#"
            tabindex="-1"
            [title]="textFor('pagerNextPage')"
            (click)="currentPage !== totalPages ? changePage(currentPage) : false"
            [ngClass]="{
                'k-link': true,
                'k-pager-nav': true,
                'k-state-disabled': disabled,
                '': true
            }"
            style="width: auto !important;">
            <span>{{textFor('pagerNextPage')}}</span>
            <span [attr.aria-label]="textFor('pagerNextPage')"
                [ngClass]="{
                    'k-icon':true,
                    'k-i-arrow-e': true
                }">
            </span>
        </a>
    `
})
export class PagerNextButtonsComponent extends PagerElementComponent {
    /**
     * @hidden
     *
     * @memberOf PagerNextButtonsComponent
     */
    public get disabled(): boolean {
        return this.currentPage === this.totalPages || !this.total;
    }

    constructor(
        localization: LocalizationService,
        pagerContext: PagerContextService,
        cd: ChangeDetectorRef
    ) {
        super(localization, pagerContext, cd);
    }

    protected onChanges({ total, skip, pageSize }: PagerContextChanges): void {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }
}

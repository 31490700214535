<ngx-slim-loading-bar [height]="'4px'" color="#fff"></ngx-slim-loading-bar>
<ngx-toasty [position]="'top-right'"></ngx-toasty>

<app-header (toggleSidebar)="sidebar.toggle()"></app-header>

<app-sidebar #sidebar [ngStyle]="{'display': !isAuthenticated ? 'none' : 'block'}"></app-sidebar>
<main class="h-100"  [ngStyle]="{'margin-left': isAuthenticated ? (sidebar.isCollapsed ? '70px' : '260px') : '0px'}" >

    <kendo-splitter #consoleSplitter orientation="vertical" style="height: 100%; overflow-y: hidden;" >
        <kendo-splitter-pane >
            <div  class="container-fluid pb-3 " style="height: 100%; overflow-y: scroll; "  *ngIf="visible" [@slideInOut]>
                <router-outlet *ngIf="visible" [@slideInOut]></router-outlet>
            </div>
        </kendo-splitter-pane>
        <kendo-splitter-pane [collapsible]="true" size="20%" *ngIf="consoleWindowService.opened">
            <ng-template appConsoleContent></ng-template>
        </kendo-splitter-pane>
    </kendo-splitter>

</main>

